/* eslint-disable no-trailing-spaces */
import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Searchbox from '../organizations_dropdown/Searchbox';
import { t } from '../i18n';
import Switch from '../components/form/Switch';
import { Col, CreateElementButton, Row } from '../components';
import EmptyState from '../components/EmptyState';
import FbAdtextTile from './FbAdtextTile';
import * as Layout from '../components/layout';
import Button from '../components/Button';

const QUERY_FB_ADTEXTS = gql`
  query GetFBAdtexts(
    $organizationId: BigInt
    $campaignSettingId: BigInt
    $fulltext: String
    $active: String
    $useDynamicFormat: Boolean
  ) {
    collection(
      identifier: "fbAdtexts"
      order: "name"
      orderDirection: "asc"
      page: 0
      limit: 1000
      organizationId: $organizationId
      campaignSettingId: $campaignSettingId
    ) {
      fbAdtexts(fulltext: $fulltext, active: $active, useDynamicFormat: $useDynamicFormat) {
        id
        name
        status
        headline
        description
        format
        creativeSource
        humanizedFormat
        preview
        fbProductSetInfo
        humanizedCreativeSource
        editLink
        previewImageUrl
      }
    }
  }
`;

const FbAdvantageAdsForm = ({ organizationId, campaignSettingId, createBtnProps, emptyState, showMenu = true }) => {
  const [search, setSearch] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const { data } = useQuery(QUERY_FB_ADTEXTS, {
    variables: {
      organizationId,
      campaignSettingId,
      fulltext: search,
      active: activeOnly ? 'only_active' : '',
      use_dynamic_format: false,
    },
  });

  const [localFbAdtexts, setLocalFbAdtexts] = useState([]);

  useMemo(() => {
    if (data?.collection?.fbAdtexts) {
      setLocalFbAdtexts(data.collection.fbAdtexts);
    }
  }, [data]);

  const updateFbAdtext = updatedFbAdtext => {
    setLocalFbAdtexts(prevFbAdtexts =>
      prevFbAdtexts.map(fbAdtext => (fbAdtext.id === updatedFbAdtext.id ? updatedFbAdtext : fbAdtext))
    );
  };

  const removeFbAdtext = fbAdtextId => {
    setLocalFbAdtexts(prevFbAdtexts => prevFbAdtexts.filter(fbAdtext => fbAdtext.id !== fbAdtextId));
  };

  const clearSearch = () => {
    setActiveOnly(false);
    setSearch('');
  };

  if (localFbAdtexts?.length === 0 && !activeOnly && !search) {
    return (
      <Layout.Col padding="l">
        <EmptyState icon={emptyState.icon} heading={emptyState.text} hasBorder>
          <CreateElementButton {...createBtnProps} />
        </EmptyState>
      </Layout.Col>
    );
  }

  return (
    <>
      {showMenu && (
        <Row className="mb-16" padding="xl">
          <Col shrink>
            <CreateElementButton {...createBtnProps} />
          </Col>
          <Col width="186px">
            <Searchbox
              searchValue={search}
              handleSearch={({ target }) => {
                setSearch(target.value);
              }}
              debounce={400}
              searchBoxText="Search"
            />
          </Col>
          <Col shrink className="justify-content-center">
            <Switch
              label={t('active_only', { scope: 'react.create_element_modal' })}
              onChange={e => {
                setActiveOnly(e.target.value);
              }}
              value={activeOnly}
              doNotUseInternalState
              name="data_source_active_only"
            />
          </Col>
        </Row>
      )}

      {localFbAdtexts?.length > 0 && (
        <Row>
          <Col>
            {localFbAdtexts?.map(item => (
              <FbAdtextTile item={item} updateFbAdtext={updateFbAdtext} removeFbAdtext={removeFbAdtext} />
            ))}
          </Col>
        </Row>
      )}
      {localFbAdtexts?.length === 0 && (activeOnly || search) && (
        <Layout.Col padding="l">
          <EmptyState
            icon={'asset_group_icon'}
            emptySearch={t('fb_adtext.no_records_description', {
              default: 'None of the records match your search query. Try adjusting what you`re looking for.',
            })}
            hasBorder
          >
            <div className="mt-16">
              <Button id="clear-search" onClick={clearSearch} primary>
                {t('react.clear_filters')}
              </Button>
            </div>
          </EmptyState>
        </Layout.Col>
      )}
    </>
  );
};

FbAdvantageAdsForm.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  createBtnProps: PropTypes.object,
  emptyState: PropTypes.object,
  showMenu: PropTypes.bool,
};

export default FbAdvantageAdsForm;
