// eslint-disable-next-line import/prefer-default-export
export const mapDataToAdPreview = data => {
  const adPreviewObject = {};
  adPreviewObject.format = data?.format;
  adPreviewObject.creativeSource = data?.creative_source;
  adPreviewObject.catalogMedia = data?.items;
  adPreviewObject.primaryText = data?.fb_adtext_primary_texts_attributes?.[0]?.text || '';
  adPreviewObject.fbPageName = data?.facebook_page?.[0]?.name || '';
  adPreviewObject.fbPageLogo = data?.facebook_page?.[0]?.cover || '';
  adPreviewObject.instagramPageName = data?.instagram_account?.[0]?.username || '';
  adPreviewObject.instagramPageLogo = data?.instagram_account?.[0]?.profile_pic || '';
  adPreviewObject.ctaText = data?.call_to_action;
  adPreviewObject.feedsMedia = data?.adtext_lines?.feeds ? { image_url: data?.adtext_lines?.feeds?.image_url } : '';
  adPreviewObject.reelsMedia = data?.adtext_lines?.stories ? { image_url: data?.adtext_lines?.stories?.image_url } : '';
  adPreviewObject.rightColumnMedia = data?.adtext_lines?.right_column
    ? { image_url: data?.adtext_lines?.right_column?.image_url }
    : '';
  adPreviewObject.url = data?.link;
  adPreviewObject.description = data?.description || '';
  adPreviewObject.headline = data?.fb_adtext_headlines_attributes?.[0]?.text || '';
  adPreviewObject.carouselMedia = data?.adtext_lines?.carousel?.sort((a, b) => a?.position - b?.position) || [];
  adPreviewObject.catalogId = data?.fb_product_set?.feed_mapping_id || '';
  return adPreviewObject;
};
