import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row, Col, Heading, Text, Icon, Button, cssVariables } from '../index';

const SimpleRadioTiles = ({ name, options, value, onChange, spacing = 8 }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = val => {
    setSelectedValue(val);
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div className="SimpleRadioTiles-Wrapper">
      {options.map(option => (
        <React.Fragment key={option.value}>
          <label>
            <Row
              className={cs(`SimpleRadioTiles-Item mb-${spacing}`, {
                'SimpleRadioTiles-Item--disabled': option.disabled,
                'SimpleRadioTiles-Item--selected': selectedValue === option.value,
              })}
              center
              justifyBetween
            >
              <Row center>
                <Col shrink>
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={selectedValue === option.value}
                    onChange={() => handleChange(option.value)}
                    className="SimpleRadioTiles-Input"
                    disabled={option.disabled}
                  />
                  <span className="SimpleRadioTiles-Indicator" />
                </Col>
                {option.icon && (
                  <Col shrink>
                    <Icon kind={option.icon} size="32px" color={cssVariables.iconMuted} />
                  </Col>
                )}
                <Col shrink>
                  {option.title && (
                    <Heading size="md" tag="h5" spacing={option.description ? 4 : 0}>
                      {option.title}
                    </Heading>
                  )}
                  {option.description && (
                    <Text size="sm" style={{ color: cssVariables.textMuted }}>
                      {option.description}
                    </Text>
                  )}
                </Col>
              </Row>
              {option.additionalContent && (
                <Col shrink>
                  <Row center>
                    {option.additionalContent?.text && (
                      <Col shrink style={{ marginRight: '12px' }}>
                        <Text size="xs" style={{ color: cssVariables.textSubtle }}>
                          {option.additionalContent?.text}
                        </Text>
                      </Col>
                    )}
                    {option.additionalContent?.ctaText && (
                      <Col shrink>
                        <Button kind="secondary" icon="bell" size="small" onClick={option.additionalContent.onClick}>
                          {option.additionalContent?.ctaText}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

SimpleRadioTiles.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      value: PropTypes.string.isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      additionalContent: PropTypes.shape({
        text: PropTypes.string,
        ctaText: PropTypes.string,
        onClick: PropTypes.func,
      }),
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  spacing: PropTypes.oneOf([8, 12, 16, 24, 32]),
};

export default SimpleRadioTiles;
