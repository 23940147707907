/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React, { useEffect, useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { groupBy, toPairs } from 'lodash';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import cs from 'classnames';
import {
  SmartSelect,
  Select,
  Icon,
  Input,
  DateInput,
  Button,
  Row,
  Col,
  Tile,
  UsedByGraph,
  UnitInput,
  Text,
  SegmentedButton,
  Badge,
} from '../components';
import PreviewDataModal from '../placeholders/PreviewDataModal';
import updatePreview from './updatePreview';
import Timeline from './Timeline';
import { formatInteger, t } from '../i18n';
import NestedFields from './index';
import { ShowConditionsFormContext } from './ConditionsWithProductSetsAndItemGroups';
import ConditionItemGroups from './ConditionItemGroups';
import { CONDITION_AND, CONDITION_OR } from '../components/ConditionsBadge';

const TIME_SELECT = [
  { label: t('react.tables.variables.before'), value: '-' },
  { label: t('react.tables.variables.after'), value: '+' },
];

export const ConditionToggleButton = ({ condition, onToggle, isReverse, direction = 'row' }) => {
  const isActiveAnd = !isReverse ? condition === CONDITION_AND : condition === CONDITION_OR || !condition;
  return (
    <div
      className={cs('Segmented', 'Segmented--withButtons', [`Segmented--${direction}`])}
      data-test-id={isReverse ? 'or-and-switch-between-groups' : 'or-and-switch-within-group'}
    >
      <div className="Segmented-wrapper">
        <Button
          className="Segmented-button"
          active={isActiveAnd}
          size="small"
          data-test-id="and-switch-button"
          onClick={onToggle}
          type="button"
          secondary
        >
          {t('react.filters_and_conditions.and_operator')}
        </Button>
        <Button
          className="Segmented-button"
          active={!isActiveAnd}
          size="small"
          data-test-id="or-switch-button"
          onClick={onToggle}
          type="button"
          secondary
          style={direction === 'row' && { minWidth: '38px' }}
        >
          {t('react.filters_and_conditions.or_operator')}
        </Button>
      </div>
    </div>
  );
};

ConditionToggleButton.propTypes = {
  condition: PropTypes.oneOf(['or', 'and']),
  direction: PropTypes.oneOf(['row', 'column']),
  onToggle: PropTypes.func.isRequired,
  isReverse: PropTypes.bool,
};

export const QUERY = gql`
  query ConditionsCount(
    $dataSourceId: BigInt
    $campaignSettingId: BigInt
    $feedMappingId: BigInt
    $conditions: [String!]
    $elasticQuery: String
  ) {
    allCount: conditionCount(
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
      feedMappingId: $feedMappingId
      elasticQuery: $elasticQuery
    )
    selectedCount: conditionCount(
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
      feedMappingId: $feedMappingId
      conditions: $conditions
      elasticQuery: $elasticQuery
    )
    errors: validateConditions(
      conditions: $conditions
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
    )
  }
`;

const Wrapper = ({ children, isOnlyOne, isFirst, isLast, hasTwoLine }) => (
  <div
    className={cs('pv-6', {
      'NestedFields-highlightedWrapper': !isOnlyOne,
      'NestedFields-highlightedWrapper--twoLine': hasTwoLine && !isOnlyOne,
      'NestedFields-highlightedWrapper--first': isFirst,
      'NestedFields-highlightedWrapper--last': isLast,
    })}
    style={{ maxWidth: 'min(100%, 800px)' }}
  >
    {children}
  </div>
);

Wrapper.propTypes = {
  isOnlyOne: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  hasTwoLine: PropTypes.bool,
  children: PropTypes.any,
};

const Condition = props => {
  const isConditionsFormShown = useContext(ShowConditionsFormContext);
  const {
    isLast,
    isFirst,
    isOnlyOne,
    disabled,
    disableRemove,
    getSubFiledOptions,
    id,
    onRemove,
    options = {},
    updateFieldData,
    group_id,
    selected_item_group_id,
    group_type,
    onToggleOrAnd,
    parentGroupType,
    itemGroups,
    onItemGroupsUpdate,
    onItemGroupsAdd,
  } = props;
  const [state, setState] = useState({
    left_part: props.left_part || '',
    right_part: props.right_part || '',
    right_part_variable: props.right_part_variable || '',
    right_part_array: props.right_part_array || [],
    equation: props.equation || '',
    dynamic_value_aggregation_function: props.dynamic_value_aggregation_function || 'percent',
    dynamic_value_percent: props.dynamic_value_percent || '',
    dynamic_value_count: props.dynamic_value_count || '',
    dynamic_value_conditions: props.dynamic_value_conditions || [],
    dynamic_value_selection: props.dynamic_value_selection || 'all_items',
    dynamic_value_conditions_local: props.dynamic_value_conditions || [],
    validationError: props._errors,
  });
  const {
    left_part,
    right_part,
    right_part_variable,
    right_part_array,
    equation,
    dynamic_value_aggregation_function,
    dynamic_value_count,
    dynamic_value_percent,
    dynamic_value_conditions,
    dynamic_value_selection,
    dynamic_value_conditions_local,
    validationError,
    ...rest
  } = state;

  const days = right_part && Math.abs(parseInt(right_part, 10));
  const sign =
    (typeof right_part !== 'undefined' && Math.sign(parseInt(right_part, 10)) < 0) || Object.is(right_part, -0) > 0
      ? '-'
      : '+';

  const [isValidationEnabled, setValidationStatus] = useState(!!props._errors);
  useEffect(() => {
    if (
      left_part.toString().length === 0 &&
      equation.toString().length === 0 &&
      right_part.toString().length === 0 &&
      right_part_variable.toString().length === 0 &&
      right_part_array.length === 0 &&
      dynamic_value_count.toString().length === 0 &&
      dynamic_value_percent.toString().length === 0 &&
      dynamic_value_selection.length === 0 &&
      dynamic_value_aggregation_function.length === 0
    ) {
      setValidationStatus(false);
    }
  }, [
    left_part,
    right_part,
    equation,
    right_part_variable,
    right_part_array,
    dynamic_value_count,
    dynamic_value_percent,
    dynamic_value_selection,
    dynamic_value_aggregation_function,
  ]);

  const hasWarningExactCountUnavailable =
    (options?.warning_exact_count_unavailable || []).indexOf(`_${left_part.replace('_modifiers.', '')}_`) !== -1;

  const conditions = selected_item_group_id
    ? {
        selected_item_group_id,
      }
    : {
        left_part,
        equation,
        right_part,
        right_part_variable,
        right_part_array,
        dynamic_value_count,
        dynamic_value_percent,
        dynamic_value_aggregation_function,
        dynamic_value_selection,
        dynamic_value_conditions_attributes: dynamic_value_conditions_local.map(dvc => ({
          left_part: dvc.left_part,
          equation: dvc.equation,
          right_part: dvc.right_part,
          right_part_variable: dvc.right_part_variable,
          right_part_array: dvc.right_part_array,
        })),
      };

  const conditionsJson = JSON.stringify(conditions);

  const [debouncedConditionsJson] = useDebounce(conditionsJson, 500);
  const { loading, data, error } = useQuery(QUERY, {
    skip: !isConditionsFormShown,
    variables: {
      ...(options.load_options_filter || {}),
      conditions: [debouncedConditionsJson],
    },
  });

  const checkGroupHasHighestLowestConditions = () => {
    const dataWithSameGroupId = props.fieldData.filter(d => d.group_id === group_id);
    const hasHighestLowestConditions = dataWithSameGroupId.some(
      d => d.equation === 'HIGHEST' || d.equation === 'LOWEST'
    );
    return hasHighestLowestConditions;
  };

  const getLeftPart = () => {
    const leftParts = options.left_part_options || [];
    if (options.enabled_left_part_names && options.enabled_left_part_names.length > 0) {
      return leftParts.filter(lp => lp.value === '' || options.enabled_left_part_names.indexOf(lp.value) !== -1);
    }
    return leftParts;
  };

  const getEquation = () => {
    const dataWithSameGroupId = props.fieldData.filter(d => d.group_id === group_id);
    const equationOptions =
      dataWithSameGroupId.length > 1
        ? options.equation_options.filter(eo => eo.value !== 'HIGHEST' && eo.value !== 'LOWEST')
        : options.equation_options || [];
    return equationOptions.filter(eq => !eq.available || eq.available.indexOf(options.available) !== -1);
  };

  const getValidateRightPart = value => {
    const type = getEquationType(left_part);

    if (type === 'number' && !/^\s*-?\s*[\d\s,.]*\s*$/.test(value)) {
      return t('errors.messages.not_a_number');
    }
    if (type === 'date' && !/^\s*-?\s*[\d\s,.]*\s*$|^\s*\d{4}-(0?[1-9]|10|11|12)-\d{2}\s*$/.test(value)) {
      return t('errors.messages.not_a_number');
    }
    return null;
  };

  const getValidateRightPartVariable = value => {
    if (!value && value.length === 0) {
      return t('errors.messages.missing_variable');
    }
    return null;
  };

  const getEquationType = () => (getLeftPart().find(option => option.value === left_part) || { type: 'all' }).type;

  const getValidEquations = () => {
    const type = getEquationType();
    return getEquation().filter(
      eq =>
        (Array.isArray(eq.type) ? eq.type.indexOf(type) !== -1 : eq.type === type) ||
        eq.type === 'all' ||
        eq.type === 'field' ||
        (left_part === 'product_id' && eq.type === 'text')
    );
  };
  const handleLeftPart = ({ target: { value } }) => {
    setState({ ...state, left_part: value, validationError: getValidateRightPart(right_part) });
    updateFieldData(id, { left_part: value });
    updatePreview();
  };
  const handleEquation = ({ target: { value } }) => {
    setState({ ...state, equation: value, validationError: getValidateRightPart(right_part) });
    updateFieldData(id, { equation: value });
    updatePreview();
  };
  const handleRightPart = ({ target: { value } }) => {
    setState({ ...state, right_part: value, validationError: getValidateRightPart(value) });
    updateFieldData(id, { right_part: value });
    updatePreview();
  };

  const handleRightPartVariable = ({ target: { value } }) => {
    setState({ ...state, right_part_variable: value, validationError: getValidateRightPartVariable(value) });
    updateFieldData(id, { right_part_variable: value });
    updatePreview();
  };

  const handleRightPartArray = ({ target: { value } }) => {
    setState({ ...state, right_part_array: value });
    updateFieldData(id, { right_part_array: value });
    updatePreview();
  };

  const handleAggregationFunction = ({ target: { value } }) => {
    setState({ ...state, dynamic_value_aggregation_function: value });
    updateFieldData(id, { dynamic_value_aggregation_function: value });
    updatePreview();
  };

  const handleDynamicValuePercent = ({ target: { value } }) => {
    setState({ ...state, dynamic_value_percent: value });
    updateFieldData(id, { dynamic_value_percent: value });
    updatePreview();
  };

  const handleDynamicSelection = ({ target: { value } }) => {
    let newState = { ...state, dynamic_value_selection: value };
    let newFieldData = { dynamic_value_selection: value };

    if (value === 'all_items') {
      const persistedConditions = newState.dynamic_value_conditions_local
        .filter(condition => condition.persisted !== false)
        .map(condition => ({ ...condition, _destroy: true }));
      newState = {
        ...newState,
        dynamic_value_conditions_local: persistedConditions,
        dynamic_value_conditions: persistedConditions,
      };
      newFieldData = { ...newFieldData, dynamic_value_conditions: persistedConditions };
    }

    setState(newState);
    updateFieldData(id, newFieldData);
    updatePreview();
  };

  const handleDynamicValueConditions = newFields => {
    const updatedConditions = newFields.filter(
      dvc => (typeof dvc.left_part !== 'undefined' && typeof dvc.equation !== 'undefined') || dvc._destroy === true
    );
    setState({ ...state, dynamic_value_conditions_local: updatedConditions });
    updateFieldData(id, { dynamic_value_conditions: updatedConditions });
    updatePreview();
  };

  const handleDynamicValueCount = ({ target: { value } }) => {
    setState({ ...state, dynamic_value_count: value });
    updateFieldData(id, { dynamic_value_count: value });
    updatePreview();
  };

  const leftPart = getLeftPart().find(left => left_part === left.value);

  const isTopCount = dynamic_value_aggregation_function === 'top_count';

  const isPercent = dynamic_value_aggregation_function === 'percent';

  const isSpecialNumber = equation === 'HIGHEST' || equation === 'LOWEST';

  const renderRightPart = () => {
    const selectedEquation = getValidEquations().find(eq => eq.value === equation);

    if (selectedEquation && selectedEquation.type === 'field') {
      return null;
    }

    if (selectedEquation && (equation === 'ONEOF' || equation === 'NOTONEOF')) {
      return (
        <SmartSelect
          value={right_part_array}
          multiple
          doNotUseInternalState
          creatable
          key={left_part}
          loadOptionsFilter={
            isConditionsFormShown &&
            options.load_options_filter &&
            left_part && { ...options.load_options_filter, field: left_part }
          }
          onChange={handleRightPartArray}
          onBlur={() => setValidationStatus(true)}
          {...getSubFiledOptions('right_part_array')}
        />
      );
    }

    if (
      selectedEquation &&
      (equation === 'DAYS>' || equation === 'DAYS<=' || equation === 'DAYS>=' || equation === 'DAYS<')
    ) {
      return (
        <Row top className="mb-0" center>
          <Col shrink>
            <UnitInput
              value={days.toString().match(/\d{4}-\d{2}-\d{2}/) ? '' : days}
              doNotUseInternalState
              key={left_part}
              min={0}
              onChange={({ target: { value } }) => {
                handleRightPart({ target: { value: sign === '+' ? Math.abs(value) : Math.abs(value) * -1 } });
              }}
              onBlur={() => setValidationStatus(true)}
              placeholder={selectedEquation?.placeholder}
              unit={t('days', { default: 'days' })}
              type="number"
              required
              style={{ maxWidth: '72px' }}
              {...getSubFiledOptions('right_part_days')}
            />
            <input type="hidden" value={right_part} {...getSubFiledOptions('right_part')} />
          </Col>
          <Col shrink>
            <Select
              value={sign}
              id="before-after-days-select"
              onChange={({ target: { value } }) => {
                handleRightPart({ target: { value: value === '+' ? Math.abs(days) : Math.abs(days) * -1 } });
              }}
              doNotUseInternalState
              collection={TIME_SELECT}
              {...getSubFiledOptions('right_part_direction')}
            />
          </Col>
          <Col shrink>
            <Text color="softGray" semibold>
              {t(sign === '-' ? 'before' : 'after', { scope: 'react.tables.variables.current_date' })}
            </Text>
          </Col>
        </Row>
      );
    }

    if (leftPart && leftPart.type === 'date') {
      return (
        <React.Fragment>
          <input type="hidden" value={right_part} {...getSubFiledOptions('right_part')} />
          <DateInput
            value={right_part}
            doNotUseInternalState
            key={left_part}
            onChange={handleRightPart}
            onBlur={() => setValidationStatus(true)}
            {...getSubFiledOptions('right_part')}
            name="condition_date_input[date]"
          />
        </React.Fragment>
      );
    }

    if (leftPart && equation.startsWith('VARIABLE')) {
      return (
        <Select
          value={right_part_variable}
          doNotUseInternalState
          onChange={handleRightPartVariable}
          {...getSubFiledOptions('right_part_variable')}
        >
          {getLeftPart().map(option =>
            hasLeftPartMissingValue && option.value === '' ? (
              <option key={option.value} value={right_part_variable}>
                {options.missing_field_text.replace('%{missing_field}', right_part_variable.replace('_modifiers.', ''))}
              </option>
            ) : (
              <option key={option.value} value={option.value}>
                {option.key}
              </option>
            )
          )}
        </Select>
      );
    }

    if (isSpecialNumber) {
      return (
        <Row>
          <Col grow wrap>
            {isPercent ? (
              <React.Fragment>
                <input type="hidden" value={dynamic_value_percent} {...getSubFiledOptions('dynamic_value_percent')} />
                <UnitInput
                  value={dynamic_value_percent}
                  doNotUseInternalState
                  key={left_part}
                  onChange={handleDynamicValuePercent}
                  onBlur={() => setValidationStatus(true)}
                  placeholder={selectedEquation?.placeholder}
                  type="number"
                  unit="%"
                  required
                  {...getSubFiledOptions('dynamic_value_percent')}
                />
              </React.Fragment>
            ) : null}
            {isTopCount ? (
              <React.Fragment>
                <input type="hidden" value={dynamic_value_count} {...getSubFiledOptions('dynamic_value_count')} />
                <UnitInput
                  value={dynamic_value_count}
                  doNotUseInternalState
                  key={left_part}
                  onChange={handleDynamicValueCount}
                  onBlur={() => setValidationStatus(true)}
                  placeholder={selectedEquation?.placeholder}
                  type="number"
                  required
                  {...getSubFiledOptions('dynamic_value_count')}
                />
              </React.Fragment>
            ) : null}
          </Col>
          <Col shrink>
            <input
              type="hidden"
              value={dynamic_value_aggregation_function}
              {...getSubFiledOptions('dynamic_value_aggregation_function')}
            />
            <SegmentedButton
              doNotUseInternalState
              size="medium"
              value={dynamic_value_aggregation_function}
              onChange={handleAggregationFunction}
              collection={['percent', 'top_count'].map(val => ({
                value: val,
                label: t(val, { scope: 'dynamic_condition.aggregation_function' }),
              }))}
              {...getSubFiledOptions('dynamic_value_aggregation_function')}
            />
          </Col>
        </Row>
      );
    }

    return (
      <Input
        value={right_part}
        doNotUseInternalState
        key={left_part}
        onChange={handleRightPart}
        onBlur={() => setValidationStatus(true)}
        placeholder={selectedEquation?.placeholder}
        {...getSubFiledOptions('right_part')}
      />
    );
  };

  const hasLeftPartMissingValue = !leftPart;
  const { twoLine } = options;

  let additionalButtonMargin = '121px';
  if (isOnlyOne) {
    additionalButtonMargin = '28px';
  } else if (twoLine) {
    additionalButtonMargin = '70px';
  }

  useEffect(() => {
    const validationErr = getValidateRightPart(right_part);
    if (validationErr) {
      setState({ ...state, validationErr });
    }
  }, []);

  const isShowingTimeline =
    leftPart &&
    leftPart?.type === 'date' &&
    (equation === 'DAYS>' ||
      equation === 'DAYS<=' ||
      equation === 'DAYS>=' ||
      equation === 'DAYS<' ||
      equation === '>' ||
      equation === '<=' ||
      equation === '>=' ||
      equation === '<');

  const showErrors =
    (left_part.toString().length > 0 ||
      equation.toString().length > 0 ||
      right_part.toString().length > 0 ||
      right_part_array.length > 0) &&
    data?.errors &&
    !loading &&
    data.errors.length > 0 &&
    isValidationEnabled;

  return (
    <React.Fragment>
      {
        <Wrapper isOnlyOne={isOnlyOne} isFirst={isFirst} isLast={isLast} hasTwoLine={twoLine}>
          <Row center data-test-id="nested-field-condition" data-test-condition-id={id}>
            <Col className="align-self-start pt-8 mr-12" width="16px">
              {showErrors ? (
                <Icon kind="warning" size="16px" color="#ff493f" />
              ) : (
                <UsedByGraph
                  size="sm"
                  kind="inverted"
                  loading={loading}
                  adSets={data?.selectedCount || 0}
                  adSetsTotal={data?.allCount || 0}
                  productsTotal={-1}
                />
              )}
            </Col>
            <Col noPadding={!twoLine}>
              {selected_item_group_id ? (
                <ConditionItemGroups {...props} selected_item_group_id={selected_item_group_id} />
              ) : (
                <>
                  <Row
                    padding="m"
                    grow
                    top
                    className={cs({ 'flex-gap-8': twoLine })}
                    flexwrap={twoLine || isSpecialNumber}
                  >
                    <Col
                      style={!twoLine && { maxWidth: !isShowingTimeline ? '296px' : 'unset' }}
                      grow
                      width={twoLine && '100%'}
                      className={cs({ 'mb-0': twoLine, 'flex-special-left-number flex-basis-0': isSpecialNumber })}
                    >
                      <Row>
                        <Col grow>
                          <Select
                            value={left_part}
                            doNotUseInternalState
                            onChange={handleLeftPart}
                            {...getSubFiledOptions('left_part')}
                          >
                            {getLeftPart().map(option =>
                              hasLeftPartMissingValue && option.value === '' ? (
                                <option key={option.value} value={left_part}>
                                  {options.missing_field_text.replace(
                                    '%{missing_field}',
                                    left_part.replace('_modifiers.', '')
                                  )}
                                </option>
                              ) : (
                                <option key={option.value} value={option.value}>
                                  {option.key}
                                </option>
                              )
                            )}
                          </Select>
                        </Col>
                        {leftPart && leftPart.previewLink ? (
                          <Col shrink>
                            <PreviewDataModal previewLink={leftPart.previewLink} name={leftPart.key}>
                              <Button type="button" tertiary onlyIcon icon="magnifier" tabIndex="-1" />
                            </PreviewDataModal>
                          </Col>
                        ) : null}
                        <Col grow>
                          <Select
                            value={equation}
                            doNotUseInternalState
                            onChange={handleEquation}
                            {...getSubFiledOptions('equation')}
                          >
                            {toPairs(groupBy(getValidEquations(), c => c.category)).map(([category, groupOptions]) =>
                              category && category !== 'undefined' ? (
                                <optgroup key={category} label={category}>
                                  {' '}
                                  {groupOptions.map(option =>
                                    hasLeftPartMissingValue && option.value === '' ? (
                                      <option key={option.value} value={left_part}>
                                        {options.missing_field_text.replace(
                                          '%{missing_field}',
                                          options.equations_texts[equation]
                                        )}
                                      </option>
                                    ) : (
                                      <option key={option.value} value={option.value}>
                                        {option.key}
                                      </option>
                                    )
                                  )}
                                </optgroup>
                              ) : (
                                groupOptions.map(option =>
                                  hasLeftPartMissingValue && option.value === '' ? (
                                    <option key={option.value} value={left_part}>
                                      {options.missing_field_text.replace(
                                        '%{missing_field}',
                                        options.equations_texts[equation]
                                      )}
                                    </option>
                                  ) : (
                                    <option key={option.value} value={option.value}>
                                      {option.key}
                                    </option>
                                  )
                                )
                              )
                            )}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className={cs('flex-shrink-2', {
                        'mb-0 mr-0': twoLine,
                        'flex-special-number flex-basis-0': isSpecialNumber,
                      })}
                      grow={!twoLine && !isShowingTimeline}
                      shrink={isShowingTimeline}
                      width={twoLine ? '100%' : undefined}
                      style={!isShowingTimeline && !isSpecialNumber && !twoLine && { minWidth: '190px' }}
                      wrap={isSpecialNumber}
                    >
                      {renderRightPart()}
                    </Col>
                  </Row>
                  {leftPart && getEquationType() === 'date' && (
                    <Timeline
                      days={
                        equation === 'DAYS>' || equation === 'DAYS<=' || equation === 'DAYS>=' || equation === 'DAYS<'
                          ? days
                          : null
                      }
                      sign={sign}
                      equation={equation}
                      date={
                        equation === 'DAYS>' ||
                        equation === 'DAYS<=' ||
                        equation === 'DAYS>=' ||
                        equation === 'DAYS<' ||
                        !moment(right_part, 'YYYY-MM-DD', true).isValid()
                          ? null
                          : right_part
                      }
                    />
                  )}
                </>
              )}
            </Col>
            <Col shrink>
              {id && onRemove && (
                <Button
                  tertiary
                  onlyIcon
                  disabled={disabled || disableRemove}
                  onClick={onRemove}
                  icon="trash"
                  tabIndex="-1"
                  type="button"
                  data-test-id="nested-field-remove"
                />
              )}
            </Col>
          </Row>
          {hasWarningExactCountUnavailable && (
            <Row className="ml-32 mt-6" shrink>
              <Col>
                <div className="ValidationWarning" data-test-id="condition-erro">
                  <Icon inheritColor kind="warning" className="mr-8" />
                  {t('react.filters_and_conditions.warning_exact_count_unavailable', {
                    default:
                      'You are editing the variable selected in the filters. As a result, we are unable to display the exact number of products at this moment.',
                  })}
                </div>
              </Col>
            </Row>
          )}
          <Row className={cs('ml-32', 'mt-6', { 'pos-absolute': !isLast && !isOnlyOne })} shrink>
            {(showErrors && (
              <Col>
                <div className="ValidationError" data-test-id="condition-erro">
                  {data.errors}
                </div>
              </Col>
            )) ||
              (!loading && data?.errors.length === 0 && (
                <Text color="gray">
                  {t('react.selected_out_of_total', {
                    default: '%{selected} out of %{total}',
                    selected: formatInteger(data?.selectedCount) || '?',
                    total: formatInteger(data?.allCount) || '?',
                  })}
                </Text>
              )) ||
              (loading && <span style={{ color: 'transparent' }}>-</span>)}
          </Row>
          {isSpecialNumber ? (
            <React.Fragment>
              <Row center flexwrap className="pl-32 w-100" padding="s">
                <Col shrink padding="n">
                  <Text size="sm" color="gray">
                    {t('dynamic_condition.select')}{' '}
                    {(isTopCount && dynamic_value_count.toString().length === 0) ||
                    (isPercent && dynamic_value_percent.toString().length === 0) ? (
                      <Badge size="medium" kind="lightOrange">
                        {t('dynamic_condition.missing_value')}
                      </Badge>
                    ) : (
                      <Badge size="medium" kind="lightGray">
                        {isTopCount ? dynamic_value_count : `${dynamic_value_percent} %`}
                      </Badge>
                    )}{' '}
                    {t('dynamic_condition.products')}{' '}
                    <Badge size="medium" kind="lightGray" style={{ textTransform: 'capitalize' }}>
                      {equation.toLowerCase()}
                    </Badge>
                    <Badge className="ml-4" size="medium" kind="lightGray" style={{ textTransform: 'capitalize' }}>
                      {left_part}
                    </Badge>{' '}
                    {t('dynamic_condition.from')}
                  </Text>
                </Col>
                <Col shrink>
                  <input
                    type="hidden"
                    value={dynamic_value_selection}
                    {...getSubFiledOptions('dynamic_value_selection')}
                  />
                  <SegmentedButton
                    doNotUseInternalState
                    value={dynamic_value_selection}
                    onChange={handleDynamicSelection}
                    size="small"
                    collection={['all_items', 'selection'].map(val => ({
                      value: val,
                      label: t(val, { scope: 'dynamic_condition.condition_target_type' }),
                      tooltipText: t(`${val}_tooltip`, { scope: 'dynamic_condition.condition_target_type' }),
                    }))}
                  />
                </Col>
              </Row>
              <Row className={cs({ hidden: dynamic_value_selection === 'all_items' })}>
                <NestedFields
                  formBase={getSubFiledOptions('x').formBase}
                  field="dynamic_value_conditions"
                  onChange={handleDynamicValueConditions}
                  fieldData={dynamic_value_conditions}
                  handleToggleOrAndParent={onToggleOrAnd}
                  parentGroupType={parentGroupType}
                  options={{
                    ...options,
                    equation_options: options.equation_options.filter(
                      eo => eo.value !== 'HIGHEST' && eo.value !== 'LOWEST'
                    ),
                    enable_global_input: false,
                  }}
                  isNestedInNested
                  key={dynamic_value_selection}
                />
              </Row>
            </React.Fragment>
          ) : null}
        </Wrapper>
      }

      {!isLast && (
        <Row className={cs('mv-4', { 'h-0': twoLine })}>
          <div className={cs({ 'negative-mt-40': twoLine })}>
            <ConditionToggleButton
              condition={group_type}
              onToggle={onToggleOrAnd}
              direction={twoLine ? 'column' : 'row'}
            />
          </div>
        </Row>
      )}

      {isLast && !checkGroupHasHighestLowestConditions() && (
        <Row center className="pt-2 mt-16">
          <Button
            className={'js-add-group-field'}
            secondary
            size="small"
            onClick={() => props.addNewField({ group_id })}
            data-test-id="add-or-condition-button"
            type="button"
            icon={'plus'}
            style={{ marginLeft: additionalButtonMargin }}
          >
            {t('react.filters_and_conditions.add_condition')}
          </Button>
          <span className="mh-8">{t('react.filters_and_conditions.or_operator_lowercase')}</span>
          <ConditionItemGroups
            {...props}
            itemGroups={itemGroups}
            onItemGroupsUpdate={onItemGroupsUpdate}
            onItemGroupsAdd={onItemGroupsAdd}
            isOnlyAddButton
          />
        </Row>
      )}
    </React.Fragment>
  );
};
Condition.propTypes = {
  _errors: PropTypes.string,
  addNewField: PropTypes.func,
  isFirst: PropTypes.bool,
  isOnlyOne: PropTypes.bool,
  isLast: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRemove: PropTypes.bool,
  equation: PropTypes.string,
  fieldCount: PropTypes.number,
  getSubFiledOptions: PropTypes.func.isRequired,
  group_type: PropTypes.string,
  group_id: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  left_part: PropTypes.string,
  right_part_array: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  options: PropTypes.object,
  right_part: PropTypes.string,
  right_part_variable: PropTypes.string,
  updateFieldData: PropTypes.func.isRequired,
  ensureEmptyRow: PropTypes.func,
  dynamic_value_count: PropTypes.number,
  dynamic_value_percent: PropTypes.number,
  dynamic_value_aggregation_function: PropTypes.string,
  dynamic_value_conditions: PropTypes.array,
  dynamic_value_selection: PropTypes.string,
  onToggleOrAnd: PropTypes.func,
  selected_item_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toggleNegative: PropTypes.bool,
  additionalStateUpdated: PropTypes.object,
  setAdditionalStateUpdated: PropTypes.func,
  parentGroupType: PropTypes.string,
  itemGroups: PropTypes.array,
  onItemGroupsUpdate: PropTypes.func,
  onItemGroupsAdd: PropTypes.func,
};

export default Condition;
