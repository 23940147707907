import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { groupBy, isEmpty, has } from 'lodash';
import { listenOnModalChange } from './RemoteModal';
import Searchbox from '../organizations_dropdown/Searchbox';
import {
  Button,
  Col,
  CreateModalBySelect,
  CreateModalBySelectContent,
  IconTile,
  InfoBox,
  Row,
  SmallHeading,
  Tooltip,
  SmartSelect,
  Text,
  Link,
  Icon,
  SectionHeading,
  cssVariables,
  Heading,
} from './index';
import { Modal, ModalBodyWithCategories } from './Modal';
import { t } from '../i18n';
import WishList from './WishList';
import betaRequestImg from '../scss_stylesheets/images/beta_request.svg';
import betaRequestedImg from '../scss_stylesheets/images/beta_request_joined.svg';
import dotIconImg from '../scss_stylesheets/images/dot_icon_32.png';

const BetaSubTile = ({ data, onClick }) => (
  <Row center justifyBetween style={{ padding: '8px' }}>
    <Col shrink style={{ marginRight: '12px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          background: cssVariables.interfaceCommonAccent,
        }}
      >
        <Icon kind={data?.icon} size="20px" color={cssVariables.interfaceSurfaceFloated} />
      </div>
    </Col>
    <Col style={{ marginRight: '12px' }}>
      <Heading tag="h3" size="md" spacing={6}>
        {data?.heading}
      </Heading>
      <Text size="sm" style={{ color: cssVariables.textMuted }}>
        {data?.description}
      </Text>
    </Col>
    <Col shrink>
      <Button
        primary
        data-test-id={data?.data_test_id}
        size="small"
        onClick={e => {
          e.preventDefault();
          onClick(data);
        }}
      >
        {data?.cta_text}
      </Button>
    </Col>
  </Row>
);

const CreateTile = ({ tile, setBeta, setTileWithSelection, selectedCountry, isRecommendedTile }) => {
  if (!tile.heading || tile.heading.length === 0) {
    return null;
  }

  // Tile with selection DS/Campaign. E.g. Data source Enrichments
  if (!tile.url) {
    const clickFunc = tile.beta ? () => setBeta(tile) : () => setTileWithSelection(tile);
    return (
      <Row shrink>
        <IconTile
          {...tile}
          newBadge={tile.newBadge}
          betaBadge={tile.betaBadge}
          moneyBadge={tile.moneyBadge}
          alphaBadge={tile.alphaBadge}
          tipBadge={tile.tipBadge}
          onClick={tile.parents && tile.parents.length === 1 ? null : clickFunc}
          ctaLink={tile.parents && tile.parents.length === 1 ? tile.parents[0].url : null}
          disabled={tile.parents && tile.parents.length === 0}
          tooltip={tile.parents && tile.parents.length === 0 ? tile.tooltip : null}
          addResource={tile.addResource}
          dataTestId={isRecommendedTile ? `${tile.data_test_id}-recommended` : tile.data_test_id}
          subTile={tile.beta_tile && <BetaSubTile data={tile.beta_tile} onClick={setBeta} />}
          subTileAltBackground
        />
      </Row>
    );
  }

  let tileLink = tile.beta ? null : tile.url;
  if (selectedCountry?.length > 0 && tileLink) {
    tileLink = `${tileLink}&country_code=${selectedCountry}`;
  }

  return (
    <Row shrink>
      <IconTile
        {...tile}
        newBadge={tile.newBadge}
        betaBadge={tile.betaBadge}
        moneyBadge={tile.moneyBadge}
        alphaBadge={tile.alphaBadge}
        tipBadge={tile.tipBadge}
        ctaLink={tileLink}
        onClick={tile.beta ? () => setBeta(tile) : tile.onClick}
        countries={tile.countries}
        addResource={tile.addResource}
        dataTestId={isRecommendedTile ? `${tile.data_test_id}-recommended` : tile.data_test_id}
        additionalContent={
          tile.beta && (
            <Button secondary size="small">
              {t('react.beta_request.join_button')}
            </Button>
          )
        }
        subTile={tile.beta_tile && <BetaSubTile data={tile.beta_tile} onClick={setBeta} />}
        subTileAltBackground
      />
    </Row>
  );
};

CreateTile.propTypes = {
  isRecommendedTile: PropTypes.bool,
  tile: PropTypes.object,
  setBeta: PropTypes.func,
  setTileWithSelection: PropTypes.func,
  selectedCountry: PropTypes.string,
};

const RequestBeta = ({ tile }) => {
  const [requested, setRequested] = useState(false);
  const [requestBeta, { loading }] = useMutation(
    gql`mutation {
      requestBeta(organizationId: ${tile.organizationId}, name: "${tile.beta}") {
        request
        errors
      }
    }`,
    {
      variables: {},
      onCompleted: () => {
        setRequested(true);
      },
    }
  );

  return (
    <Col center>
      {!requested && (
        <>
          <img src={betaRequestImg} alt="Beta request illustration" className="Image mb-24" />
          <SectionHeading>{t('react.beta_request.heading')}</SectionHeading>
          <Text className="mb-16" size="xs" uppercase spaced bold>
            {tile.heading}
          </Text>
          <Text className="text-center mb-16">{t('react.beta_request.description')}</Text>
          <Text className="text-center mb-16">{t('react.beta_request.next_description')}</Text>
          <Button primary disabled={loading} data-test-id="join-beta-program-button" onClick={() => requestBeta()}>
            {t('react.beta_request.button')}
          </Button>
        </>
      )}
      {requested && (
        <>
          <img src={betaRequestedImg} alt="Beta request illustration" className="Image mb-24" />
          <SectionHeading>{t('react.beta_request.requested.heading')}</SectionHeading>
          <Text className="mb-16" size="xs" uppercase spaced bold>
            {tile.heading}
          </Text>
          <Text className="text-center mb-16">{t('react.beta_request.requested.description')}</Text>
          <Text className="text-center mb-16">{t('react.beta_request.requested.next_description')}</Text>
          <Text className="text-center mb-16">{t('react.beta_request.requested.last_description')}</Text>
          <Button data-dismiss="modal" data-test-id="requested-beta-program-button" primary>
            {t('react.beta_request.requested.button')}
          </Button>
        </>
      )}
    </Col>
  );
};

RequestBeta.propTypes = {
  tile: PropTypes.object,
};

export const CreateElementTiles = ({
  tiles,
  setBeta,
  setTileWithSelection,
  selectedCountry,
  isSearch,
  isCategorySelected,
  isRecommendedTile,
}) => {
  const visibleTiles = isSearch || isCategorySelected ? tiles : tiles.filter(tile => !tile.showMore);
  const hiddenTiles = !isSearch && !isCategorySelected ? tiles.filter(tile => tile.showMore) : [];
  const [showMoreTiles, setShowMoreTiles] = useState(false);

  if (hiddenTiles.length > 0 || visibleTiles.length > 0) {
    return (
      <React.Fragment>
        {visibleTiles.map(tile => (
          <CreateTile
            key={[tile?.icon, tile?.category, tile?.heading].join('-')}
            setBeta={setBeta}
            tile={tile}
            setTileWithSelection={setTileWithSelection}
            selectedCountry={selectedCountry}
            isRecommendedTile={isRecommendedTile}
          />
        ))}
        {hiddenTiles?.length > 0 && (
          <React.Fragment>
            {showMoreTiles &&
              hiddenTiles.map(tile => (
                <CreateTile
                  key={[tile?.icon, tile?.category, tile?.heading].join('-')}
                  setBeta={setBeta}
                  tile={tile}
                  setTileWithSelection={setTileWithSelection}
                  selectedCountry={selectedCountry}
                  isRecommendedTile={isRecommendedTile}
                />
              ))}
            <Link
              className="mt-4 mb-16"
              onClick={() => setShowMoreTiles(!showMoreTiles)}
              data-test-id={`show-more-link-${hiddenTiles[0].category.replace('_', '-')}`}
            >
              <span className="d-flex">
                {showMoreTiles ? t('react.create_element_modal.hide_more') : t('react.create_element_modal.show_more')}
                <Icon kind={showMoreTiles ? 'chevron-up' : 'chevron-down'} size="8px" inheritColor className="ml-4" />
              </span>
            </Link>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  return null;
};

CreateElementTiles.propTypes = {
  isCategorySelected: PropTypes.bool,
  isRecommendedTile: PropTypes.bool,
  isSearch: PropTypes.bool,
  selectedCountry: PropTypes.string,
  setBeta: PropTypes.func,
  setTileWithSelection: PropTypes.func,
  tiles: PropTypes.any,
};

export const groupByCountries = tiles =>
  tiles.reduce((acc, cur) => {
    let temp = { ...acc };

    if (has(cur, 'countries')) {
      Object.keys(cur.countries).forEach(key => {
        if (temp?.[key]) {
          temp = { ...temp, [key]: [...temp[key], cur] };
        } else temp = { ...temp, [key]: [cur] };
      });
    }

    return temp;
  }, {});

const sortByCountryName = countriesArray =>
  countriesArray.sort((a, b) => {
    if (a[0] === 'ALL') return -1;
    return b[0] === 'ALL' ? 1 : a[1].localeCompare(b[1]);
  });

export const CreateElementModal = ({
  countries,
  description,
  heading,
  onClose,
  parentKind,
  parents,
  preSelectedItem,
  sidebarAllItem,
  sorted,
  tiles,
  type,
  wishList,
  addResource,
  introWizard = false,
  fullWidthContent,
}) => {
  const [selectedBeta, setBeta] = useState(null);
  const [tileWithSelection, setTileWithSelection] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedCategoryTemp, setSelectedCategory] = useState(null);
  const [selectedCountryTemp, setSelectedCountry] = useState(null);
  const [wishlistHedingVisibility, setWishlistHedingVisibility] = useState('visible');

  const searchedTiles = tiles.filter(
    ({ heading: name, description: desc }) =>
      !search || name.toLowerCase().includes(search.toLowerCase()) || desc?.toLowerCase().includes(search.toLowerCase())
  );

  const recommendedTiles = tiles.filter(({ recommended }) => recommended);

  const groupCategoryOriginal = groupBy(tiles, 'category');
  const groupCountryOriginal = groupByCountries(tiles);
  const sortedCountriesOriginal = sortByCountryName(
    Object.keys(groupCountryOriginal).map(countryCode =>
      countryCode === 'ALL'
        ? ['ALL', t('react.create_element_modal.all_countries')]
        : [countryCode, countries[countryCode]]
    )
  );

  const groupCategory = groupBy(searchedTiles, 'category');
  const groupCountries = groupByCountries(searchedTiles);

  const selectedCountry = Object.keys(groupCountries).indexOf(selectedCountryTemp) !== -1 ? selectedCountryTemp : null;
  const selectedCategory =
    Object.keys(groupCategory).indexOf(selectedCategoryTemp) !== -1 ? selectedCategoryTemp : null;

  const filtredTiles = searchedTiles
    .filter(el => !selectedCategory || el.category === selectedCategory)
    .filter(
      el =>
        !selectedCountry || selectedCountry === 'ALL' || has(el.countries, selectedCountry) || has(el.countries, 'ALL')
    )
    .map(el =>
      !selectedCountry || selectedCountry === 'ALL'
        ? el
        : {
            ...el,
            countries: has(el.countries, 'ALL')
              ? { [selectedCountry]: el.countries.ALL }
              : { [selectedCountry]: el.countries[selectedCountry] },
          }
    );

  const groupFilteredCategory = groupBy(filtredTiles, 'category');

  if (parents.length > 0) {
    return (
      <CreateModalBySelect
        parentKind={parentKind}
        items={parents}
        type={type}
        onClose={onClose}
        preSelectedItem={preSelectedItem}
      />
    );
  }

  const Component = introWizard ? 'div' : Modal;

  return (
    <Component onClose={onClose} heading={!tileWithSelection ? heading : undefined} size="medium">
      {tileWithSelection && (
        <CreateModalBySelectContent
          items={tileWithSelection.parents}
          type={tileWithSelection.type}
          onClose={onClose}
          backArrowFunc={() => setTileWithSelection(null)}
          forceSelectedItem={tileWithSelection}
        />
      )}

      {!tileWithSelection && (
        <ModalBodyWithCategories
          groupCategoryOriginal={groupCategoryOriginal}
          groupCategory={groupCategory}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          sidebarAllItem={sidebarAllItem}
          fullWidthContent={fullWidthContent}
        >
          {!selectedBeta && (
            <Row className="mb-8">
              <Col grow>
                <Searchbox
                  searchBoxText={t('search_title')}
                  searchValue={search}
                  handleSearch={({ target: { value } }) => setSearch(value)}
                  hideReset
                />
              </Col>
              {!isEmpty(groupCountryOriginal) && (
                <Col width="144px">
                  <SmartSelect
                    placeholder="Country"
                    key={selectedCountry || 'ALL'}
                    id="country-select"
                    name="country_select"
                    collection={sortedCountriesOriginal}
                    onChange={({ target: { value } }) => setSelectedCountry(value)}
                    value={selectedCountry || 'ALL'}
                    initWithoutSelectedValue
                    doNotUseInternalState
                  />
                </Col>
              )}
            </Row>
          )}
          {description?.length > 0 && filtredTiles.length > 0 && <Text className="mb-8">{description}</Text>}
          {filtredTiles.length === 0 && !wishList && (
            <InfoBox type="info" withIcon className="mt-8">
              {t('react.create_element_modal.no_results')}
            </InfoBox>
          )}
          {!selectedBeta && !selectedCategory && !search && !!recommendedTiles?.length && (
            <>
              <Row center className="mt-8">
                <img src={dotIconImg} className="mr-8" alt="Dotidot hoppy" width="16px" height="16px" />
                <SmallHeading spacing={0}>
                  {t('react.create_element_modal.category_subheading.recommended')}
                </SmallHeading>
              </Row>
              <CreateElementTiles
                justifyCenter
                tiles={recommendedTiles}
                setBeta={setBeta}
                setTileWithSelection={setTileWithSelection}
                className="mb-0"
                selectedCountry={selectedCountry}
                addResource={addResource}
                isRecommendedTile
              />
            </>
          )}
          {!selectedBeta &&
            Object.keys(groupFilteredCategory).map(category => (
              <React.Fragment key={category}>
                {category !== 'undefined' && category !== 'without_category' && (
                  <SmallHeading className="mt-8">
                    {t(category, { scope: 'react.create_element_modal.category_subheading' })}
                  </SmallHeading>
                )}
                <CreateElementTiles
                  justifyCenter
                  tiles={
                    sorted
                      ? groupFilteredCategory[category]?.sort((a, b) => a.heading.localeCompare(b.heading))
                      : groupFilteredCategory[category]
                  }
                  setBeta={setBeta}
                  setTileWithSelection={setTileWithSelection}
                  className="mb-0"
                  selectedCountry={selectedCountry}
                  isSearch={search?.length > 0}
                  isCategorySelected={!!selectedCategory}
                  addResource={addResource}
                />
              </React.Fragment>
            ))}
          {wishList && (
            <Row>
              <Col>
                {wishlistHedingVisibility === 'visible' && (
                  <SmallHeading className="mt-8">{wishList.heading}</SmallHeading>
                )}
                <WishList
                  category={wishList.category}
                  search={search}
                  enableQuickWishList={filtredTiles.length === 0}
                  callbackFn={value =>
                    value ? setWishlistHedingVisibility('hidden') : setWishlistHedingVisibility('visible')
                  }
                />
              </Col>
            </Row>
          )}
          {selectedBeta && (
            <Row>
              <RequestBeta tile={selectedBeta} />
            </Row>
          )}
        </ModalBodyWithCategories>
      )}
    </Component>
  );
};

CreateElementModal.propTypes = {
  countries: PropTypes.object,
  description: PropTypes.string,
  heading: PropTypes.string,
  introWizard: PropTypes.bool,
  onClose: PropTypes.func,
  parents: PropTypes.any,
  preSelectedItem: PropTypes.bool,
  sidebarAllItem: PropTypes.string,
  sorted: PropTypes.bool,
  tiles: PropTypes.any,
  type: PropTypes.string,
  wishList: PropTypes.object,
  parentKind: PropTypes.string,
  addResource: PropTypes.object,
};

const getTestId = ({ onlyOneTile, firstElement, data_test_id }) => {
  if (onlyOneTile) return firstElement.data_test_id || data_test_id;
  return data_test_id;
};

export const CreateElementButtonComponent = ({
  buttonText,
  buttonKind = 'primary',
  disableIcon = false,
  description,
  data_test_id,
  tiles,
  parents,
  heading,
  wishList,
  className,
  hideText,
  asyncLoading,
  asyncLoad,
  sidebarAllItem,
  tooltip,
  toolbox,
  type,
  countries,
  sorted,
  parentKind,
  addResource,
  shouldCloseOnSuccess,
  fullWidthContent,
}) => {
  const icon = toolbox?.icon || 'plus';

  const [showModal, setModal] = useState(window.location.search.indexOf('showCreateModal') !== -1);

  useEffect(() => (shouldCloseOnSuccess ? listenOnModalChange(() => setModal(false)) : undefined), [setModal]);

  if (asyncLoad) {
    return (
      <Button
        {...toolbox}
        tag="button"
        icon={disableIcon ? null : icon}
        kind={buttonKind}
        disabled={asyncLoading}
        onClick={() => {
          asyncLoad();
          setModal(true);
        }}
        className={`${className} ${hideText && 'Button--hideText'}`}
        data-test-id={getTestId({ onlyOneTile: false, firstElement: null, data_test_id })}
        onlyIcon={!buttonText || buttonText.length === 0}
      >
        {buttonText}
      </Button>
    );
  }

  if ((tiles && tiles.length > 0) || (parents && parents.length > 0)) {
    const onlyOneTile = type !== 'adtext' && type !== 'sitelink' && (tiles.length === 1 || parents.length === 1);
    const firstElement = tiles[0] || parents[0];
    const preSelectedItem = (type === 'adtext' || type === 'sitelink') && parents.length === 1 && parents[0];

    return (
      <React.Fragment>
        <Tooltip text={tooltip}>
          <Button
            {...toolbox}
            tag={onlyOneTile ? 'a' : 'button'}
            icon={disableIcon ? null : icon}
            kind={buttonKind}
            href={onlyOneTile ? firstElement.url : undefined}
            onClick={!onlyOneTile ? () => setModal(true) : undefined}
            className={`${className} ${hideText && 'Button--hideText'}`}
            data-test-id={getTestId({ onlyOneTile, firstElement, data_test_id })}
            onlyIcon={!buttonText || buttonText.length === 0}
          >
            {buttonText}
          </Button>
        </Tooltip>
        {showModal && !onlyOneTile && (
          <CreateElementModal
            tiles={tiles}
            parents={parents}
            parentKind={parentKind}
            heading={heading}
            onClose={() => setModal(false)}
            type={type}
            preSelectedItem={preSelectedItem}
            wishList={wishList}
            sidebarAllItem={sidebarAllItem}
            countries={countries}
            description={description}
            sorted={sorted}
            addResource={addResource}
            fullWidthContent={fullWidthContent}
          />
        )}
      </React.Fragment>
    );
  }

  if (buttonText) {
    return (
      <Button kind={buttonKind} disabled icon="plus" data-test-id={data_test_id}>
        {buttonText}
      </Button>
    );
  }
  return null;
};

CreateElementButtonComponent.propTypes = {
  asyncLoading: PropTypes.bool,
  asyncLoad: PropTypes.func,
  buttonKind: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  countries: PropTypes.object,
  data_test_id: PropTypes.string,
  disableIcon: PropTypes.bool,
  description: PropTypes.string,
  heading: PropTypes.string,
  hideText: PropTypes.bool,
  parentKind: PropTypes.string,
  parents: PropTypes.any,
  sidebarAllItem: PropTypes.string,
  sorted: PropTypes.bool,
  tiles: PropTypes.any,
  toolbox: PropTypes.object,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  wishList: PropTypes.object,
  addResource: PropTypes.object,
  shouldCloseOnSuccess: PropTypes.bool,
};

CreateElementButtonComponent.defaultProps = { hideText: true };

const QUERY = asyncQuery => gql`
  query($organizationId: BigInt) {
    organization(id: $organizationId) { createButtonProps: ${asyncQuery} }
  }
`;

export const CreateElementButton = ({ organizationId, asyncQuery, ...rest }) => {
  if (asyncQuery) {
    const [loadItems, { loading, data }] = useLazyQuery(QUERY(asyncQuery), {
      variables: { organizationId },
    });

    const createButtonData = data?.organization?.createButtonProps;
    return (
      <CreateElementButtonComponent
        {...createButtonData}
        {...rest}
        asyncLoad={createButtonData ? undefined : () => loadItems()}
        asyncLoading={loading}
      />
    );
  }

  return <CreateElementButtonComponent {...rest} />;
};

CreateElementButton.propTypes = {
  organizationId: PropTypes.number,
  asyncQuery: PropTypes.string,
};

export default CreateElementButton;
