import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row } from '../layout';

const SourcesMenu = ({ sources, selectedSource, setSource }) => (
  <Row className="MediaCategories mt-24">
    {sources.map(source => (
      <div
        key={source.label}
        className={cs('MediaCategories-item', { active: source.value === selectedSource })}
        onClick={() => setSource(source.value)}
      >
        {source.label}
      </div>
    ))}
  </Row>
);

SourcesMenu.propTypes = {
  sources: PropTypes.array.isRequired,
  selectedSource: PropTypes.string.isRequired,
  setSource: PropTypes.func.isRequired,
};

export default SourcesMenu;
