import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import cs from 'classnames';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Modal,
  ModalHeader,
  ModalHeading,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Heading,
  Text,
  EmptyState,
  cssVariables,
  Button,
  InfoBox,
  Icon,
  Pagination,
} from '../index';
import { t } from '../../i18n';
import { UploadNewMedia, MediaTile, SourcesMenu } from './index';
import withApiStateHandler from '../withApiStateHandler';
import useSearch from '../../hooks/useSearch';
import Searchbox from '../../organizations_dropdown/Searchbox';
import { SOURCES, PAGE_LIMIT } from './meta';

const widthHeightProps = {
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
};

const IMAGES_QUERY = gql`
  query($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        fbAdImages {
          id
          name
          url
          width
          height
        }
      }
      images {
        id
        filename
        url
        width
        height
      }
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation DeleteImage($id: BigInt!) {
    deleteImage(id: $id) {
      image {
        id
        deleted
      }
    }
  }
`;

const SelectImageModalList = ({
  refetch,
  onSelect,
  minWidth = 600,
  minHeight = 600,
  images,
  selectedMedia,
  selectedSource,
  ...props
}) => {
  const [deleteImage] = useMutation(DELETE_IMAGE, { onCompleted: () => refetch() });
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mediaIdToDelete, setMediaIdToDelete] = useState(null);

  const { search, setSearch, result } = useSearch({
    collection: images,
    ignoreKeys: ['id', 'height', 'url', 'width', '__typename'],
  });

  useEffect(() => {
    setSearch('');
  }, [selectedSource]);

  const paginatedImages = useMemo(() => result.slice(0 + PAGE_LIMIT * currentPage, PAGE_LIMIT * (currentPage + 1)), [
    currentPage,
    result,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [result]);

  const onSelectHandler = (media, disabled) => {
    if (disabled) return;
    if (media.id === selectedMedia?.id) {
      onSelect({});
      return;
    }
    onSelect({
      xlinkHref: media.url,
      width: media.width,
      height: media.height,
      filename: media.filename,
      id: media.id,
    });
  };

  const deleteMediaHandler = () => {
    if (selectedMedia?.id === mediaIdToDelete) {
      onSelect({});
    }
    deleteImage({
      variables: {
        id: mediaIdToDelete,
        deleted: true,
      },
    });
    setShowDeleteModal(false);
  };

  return (
    <>
      <Row grow>
        <div className="w-100">
          <Row className="mb-16" padding="xl" shrink>
            <Col width="392px">
              <Searchbox
                searchBoxText={t('react.image_generator.modal.search')}
                searchValue={search}
                handleSearch={({ target: { value } }) => setSearch(value)}
              />
            </Col>
          </Row>
          {props.uploadError && (
            <Row className="mb-16">
              <Col grow>
                <InfoBox type="error" withIcon onClose={() => props.setUploadError(false)}>
                  <span>
                    <b style={{ marginRight: '8px' }}>
                      {t('media.image_upload_error', { default: 'Image upload error' })}
                    </b>
                    {t(`media.image_upload_error_${props.uploadError}`)}
                  </span>
                </InfoBox>
              </Col>
            </Row>
          )}
          <Row>
            <Col grow>
              <InfoBox type="info" withIcon>
                <span>
                  <Heading size="lg" spacing={8} tag="h3">
                    {t('media.media_requirements', { default: 'Image and video requirements' })}
                  </Heading>
                  <ul style={{ paddingLeft: '20px' }}>
                    <li>
                      {t('media.requirements.image600x600', {
                        default:
                          'Maximum image size is 30MB, minimum resolution is 600x600 px in jpg, png and webp format.',
                      })}
                    </li>
                    <li>
                      {t('media.requirements.video_from_fb', {
                        default:
                          'If you need use video in your ads, please upload it directly to Facebook then you can see it here.',
                      })}
                    </li>
                  </ul>
                </span>
              </InfoBox>
            </Col>
          </Row>

          <Row padding="l" flexwrap className="mv-16 Grid--fifthWithoutMargin" style={{ width: 924, maxWidth: '100%' }}>
            {images.length === 0 && (
              <EmptyState icon="image_editor_icon">
                <Heading className="mt-24" tag="h3">
                  {t('media.no_media', {
                    default: 'No Media Yet',
                  })}
                </Heading>
                <Text className="MediaUpload-emptyDescription">
                  {t('media.no_media_description', {
                    default:
                      "Your media library is currently empty. Start by uploading your first media. Once you do, they'll appear here.",
                  })}
                </Text>
              </EmptyState>
            )}

            {result.length === 0 && images.length > 0 && (
              <EmptyState icon="image_editor_no_images">
                <Heading className="mt-24" tag="h3">
                  {t('media.no_results_found', {
                    default: 'No Results Found',
                  })}
                </Heading>
                <Text className="MediaUpload-emptyDescription">
                  {t('media.no_results_found_description', {
                    default:
                      'We couldn’t find any media matching your search. Try adjusting your search terms. You can also upload new media to find exactly what you need.',
                  })}
                </Text>
              </EmptyState>
            )}

            {paginatedImages.map(image => {
              const disabled = (minWidth && image.width < minWidth) || (minHeight && image.height < minHeight);
              return (
                <Col width="172px" key={image.id}>
                  <MediaTile
                    label={image.name}
                    url={image.url}
                    resolution={`${image.width}x${image.height}`}
                    source={image.source}
                    onSelect={() => onSelectHandler(image, disabled)}
                    onDelete={e => {
                      e.stopPropagation();
                      setMediaIdToDelete(image.id);
                      setShowDeleteModal(true);
                    }}
                    disabled={disabled}
                    selected={
                      selectedMedia?.id === image.id || selectedMedia?.image_id?.toString() === image.id.toString()
                    }
                  />
                </Col>
              );
            })}
          </Row>

          <Row justifyEnd>
            <Col shrink>
              <Pagination
                className="mt-16"
                current={currentPage}
                disableAnchor
                changePage={page => () => setCurrentPage(page)}
                maxPages={Math.ceil(result?.length / PAGE_LIMIT)}
              />
            </Col>
          </Row>
        </div>
      </Row>
      {showDeleteModal && (
        <Modal size="small" onClose={() => setShowDeleteModal(false)}>
          <ModalHeader>
            <ModalHeading>
              <Row center>
                <Icon kind="trash" size="24px" className="mr-12" color={cssVariables.interactiveAttentionDefault} />
                {t('media.delete_media_modal_heading', { default: 'Delete media' })}
              </Row>
            </ModalHeading>
          </ModalHeader>
          <ModalBody>
            {t('media.delete_media_modal_body', {
              default:
                'Are you sure you want to delete this media? This action cannot be undone. Please confirm if you want to proceed with the deletion.',
            })}
          </ModalBody>
          <ModalFooter>
            <Button red icon="trash" onClick={deleteMediaHandler}>
              {t('media.delete_media_modal_confirm', { default: 'Delete' })}
            </Button>
            <Button secondary onClick={() => setShowDeleteModal(false)}>
              {t('media.delete_media_modal_cancel', { default: 'Cancel' })}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const SelectImageModalContent = withApiStateHandler(({ refetch, images, uploadError, setUploadError, ...props }) => (
  <Row padding="xl">
    <Col width="220px">
      <UploadNewMedia
        organizationId={props.organizationId}
        key={uploadError}
        onError={setUploadError}
        onImageUpdate={refetch}
      />
      <SourcesMenu sources={SOURCES} selectedSource={props.selectedSource} setSource={props.setSelectedSource} />
    </Col>
    <Col grow className="pr-8">
      <SelectImageModalList
        {...props}
        uploadError={uploadError}
        setUploadError={setUploadError}
        refetch={refetch}
        images={images}
      />
    </Col>
  </Row>
));

SelectImageModalContent.propTypes = {
  ...widthHeightProps,
  images: PropTypes.array,
  onSelect: PropTypes.func,
  refetch: PropTypes.func,
  uploadError: PropTypes.bool,
  setUploadError: PropTypes.func,
  selectedSource: PropTypes.string,
  setSelectedSource: PropTypes.func,
  organizationId: PropTypes.number,
};

const AdSelectMediaModal = ({ onClose, onConfirm, confirmButtonText, organizationId, campaignSettingId, ...props }) => {
  const [selectedSource, setSelectedSource] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const { loading, data, refetch, error } = useQuery(IMAGES_QUERY, {
    variables: { organizationId, campaignSettingId },
    fetchPolicy: 'network-only',
  });

  const localImages = data?.organization?.images || [];
  const fbImages = data?.organization?.campaignSetting?.fbAdImages || [];

  const localImagesMapped = localImages.map(image => ({
    ...image,
    name: image.filename,
    source: 'Dotidot',
  }));

  const fbImagesMapped = fbImages.map(image => ({
    ...image,
    name: image.name,
    source: 'Facebook',
  }));

  const allMedia = [...localImagesMapped, ...fbImagesMapped];
  const allMediaFiltered = allMedia.filter(image => selectedSource === '' || image.source === selectedSource);

  return (
    <Modal
      onClose={onClose}
      size="dynamic"
      heading={
        <Row center>
          <Icon kind="image-widget" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
          {t('media.select_media')}
        </Row>
      }
    >
      <ModalBody classNames={cs({ 'overflow-visible': loading })}>
        <SelectImageModalContent
          {...props}
          refetch={refetch}
          error={error}
          loading={loading}
          images={allMediaFiltered}
          uploadError={uploadError}
          setUploadError={setUploadError}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          organizationId={organizationId}
        />
      </ModalBody>

      {localImages?.length > 0 && !loading && !error && (
        <ModalFooter>
          <Button kind="primary" onClick={onConfirm} disabled={!props.selectedMedia?.id}>
            {confirmButtonText || 'Confirm'}
          </Button>
          <Button kind="secondary" onClick={onClose}>
            {t('media.cancel', { default: 'Cancel' })}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

AdSelectMediaModal.propTypes = {
  ...widthHeightProps,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmButtonText: PropTypes.string,
  organizationId: PropTypes.number,
  selectedMedia: PropTypes.object,
};

export default hot(AdSelectMediaModal);
