import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row, Col, Text, Icon, cssVariables, SimpleTooltip } from '../index';
import { t } from '../../i18n';

const SimpleInput = ({
  id,
  error,
  name = '',
  label,
  onChange,
  optional,
  required,
  placeholder = '',
  maxLength,
  tooltip,
  type = 'text',
  value = '',
  assistiveText,
  assistive_text,
  showClearButton = true,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    if (touched) {
      if (inputValue.length > maxLength) {
        setErrorMessage(
          t('react.simple_input.exceeds_max_length', {
            default: "You've exceeded the character limit",
          })
        );
      } else if (required && inputValue.length === 0) {
        setErrorMessage(t('react.simple_input.required_field', { default: 'This field is required' }));
      } else {
        setErrorMessage('');
      }
    }
  }, [inputValue, touched]);

  useEffect(() => {
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  const handleChange = val => {
    setInputValue(val);
  };

  const handleClearInput = () => {
    setInputValue('');
  };

  const aText = assistiveText || assistive_text;

  return (
    <div className="SimpleInput-Wrapper">
      {id && label && (
        <Row center style={{ marginBottom: '6px' }}>
          <Col shrink>
            <label htmlFor={id}>
              <Text medium style={{ marginRight: '4px' }}>
                {label}
              </Text>
              {optional && (
                <Text style={{ color: cssVariables.textSubtle, marginRight: '4px' }}>
                  ({t('react.smart_select.optional', { default: 'optional' })})
                </Text>
              )}
              {required && (
                <Text medium style={{ color: cssVariables.interactiveAttentionDefault }}>
                  *
                </Text>
              )}
            </label>
          </Col>
          {tooltip && (
            <Col shrink>
              <SimpleTooltip text={tooltip}>
                <Icon color={cssVariables.textGhost} kind="info-circle-fill" size="16px" />
              </SimpleTooltip>
            </Col>
          )}
        </Row>
      )}
      <div className="SimpleInput-Item">
        <div
          className={cs('SimpleInput-Input', { 'SimpleInput-Input--error': errorMessage, 'has-error': errorMessage })}
        >
          <input
            id={id}
            name={name}
            type={type}
            value={inputValue}
            onChange={e => handleChange(e.target.value)}
            placeholder={placeholder}
            onBlur={() => setTouched(true)}
          />
          {showClearButton && (
            <Icon
              onClick={handleClearInput}
              kind="close-outline-squared"
              size="16px"
              color={cssVariables.iconGhost}
              className="DynamicInput-ClearIcon"
            />
          )}
        </div>
        <Row center justifyBetween style={{ marginTop: '7px' }}>
          <Col grow>
            {errorMessage && (
              <Row center padding="s">
                <Col shrink>
                  <Icon color={cssVariables.statusAttentionDefault} kind="error-circle" size="16px" />
                </Col>
                <Col>
                  <Text style={{ color: cssVariables.statusAttentionDefault, marginTop: '1px' }}>{errorMessage}</Text>
                </Col>
              </Row>
            )}
            {aText && !errorMessage && (
              <Row center padding="s">
                <Col shrink>
                  <Icon color={cssVariables.iconSubtle} kind="info-circle" size="16px" />
                </Col>
                <Col shrink>
                  <Text style={{ color: cssVariables.textSubtle, marginTop: '1px' }}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: aText }}
                    />
                  </Text>
                </Col>
              </Row>
            )}
          </Col>
          {maxLength && (
            <Col shrink style={{ paddingTop: '1px' }}>
              <Text
                size="xs"
                style={{ color: errorMessage ? cssVariables.statusAttentionDefault : cssVariables.textSubtle }}
              >
                {inputValue.length}/{maxLength}
              </Text>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

SimpleInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.array.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string || PropTypes.array,
};

export default SimpleInput;
