import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { gql, useMutation } from '@apollo/client';
import { Modal, ModalFooter, ModalBody } from '../components/Modal';
import { DropdownMenu, Input, Link, Row, Switch, Text } from '../components';
import defaultImage from '../../../assets/images/empty_container/image_editor_no_images.png';
import Button from '../components/Button';

const UPDATE_FB_ADTEXT_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $name: String) {
    updateFbAdtext(id: $id, name: $name) {
      fbAdtext {
        id
        name
        status
        headline
        description
        format
        creativeSource
      }
    }
  }
`;

const UPDATE_FB_ADTEXT_STATUS_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $status: String) {
    updateFbAdtext(id: $id, status: $status) {
      fbAdtext {
        id
        name
        status
        headline
        description
        format
        creativeSource
      }
    }
  }
`;

const DELETE_FB_ADTEXT_MUTATION = gql`
  mutation DeleteFbAdtext($id: BigInt!) {
    deleteFbAdtext(id: $id) {
      fbAdtext {
        id
      }
    }
  }
`;

const FbAdtextTile = ({ item, updateFbAdtext, removeFbAdtext }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tempName, setTempName] = useState(item.name);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [updateFBAdtextMutation] = useMutation(UPDATE_FB_ADTEXT_MUTATION);
  const [deleteFBAdtextMutation] = useMutation(DELETE_FB_ADTEXT_MUTATION);
  const [updateFBAdtextStatus] = useMutation(UPDATE_FB_ADTEXT_STATUS_MUTATION);

  const openEditModal = () => {
    setTempName(item.name);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleNameChange = e => {
    setTempName(e.target.value);
  };

  const handleSave = async () => {
    try {
      const { data } = await updateFBAdtextMutation({
        variables: {
          id: item.id,
          name: tempName,
        },
      });
      updateFbAdtext(data.updateFbAdtext);
      closeEditModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item:', error);
    }
  };

  const handleSwitchChange = async value => {
    setLoading(true);
    try {
      const { data } = await updateFBAdtextStatus({
        variables: {
          id: item.id,
          status: value ? 'running' : 'paused',
        },
      });
      updateFbAdtext({ ...item, status: data.updateItemStatus.status });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFBAdtextMutation({
        variables: {
          id: item.id,
        },
      });
      removeFbAdtext(item.id);
      closeDeleteModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting item:', error);
    }
  };

  if (!item) return null;

  return (
    <div
      className={cs(
        'Preview-tileItem',
        'Preview-tileItem--dragable',
        'Preview-tileItem--hoverable',
        'Preview-tileItem--spaced',
        'mv-8'
      )}
      style={{ zIndex: 'auto' }}
    >
      <Row>
        <Switch
          pausePlay
          doNotUseInternalState
          value={item.status === 'running'}
          onChange={({ target: { value } }) => handleSwitchChange(value)}
        />
        <img
          src={item.previewImageUrl ? item.previewImageUrl : defaultImage}
          style={{ maxHeight: '50px', maxWidth: '80px' }}
          alt="Initial training"
          className="mb-24 mw-100 border-top-left-radius-8 border-top-right-radius-8"
        />

        <Link href={item.editLink}>{item.name}</Link>

        <Text color="gray" className="w-100" size="sm">
          {item.humanizedFormat} / {item.humanizedCreativeSource}
        </Text>
        <Link icon="edit" href={item.editLink} />
        <DropdownMenu>
          <Switch
            label={item.status === 'running' ? 'Pause ad' : 'Run ad'}
            pausePlay
            doNotUseInternalState
            value={item.status === 'running'}
            onChange={({ target: { value } }) => handleSwitchChange(value)}
          />
          {/* <Link icon={'duplicate'}>Duplicate</Link> */}
          <Link icon={'type'} onClick={openEditModal}>
            Rename
          </Link>
          <Link danger icon={'trash'} onClick={openDeleteModal}>
            Delete
          </Link>
        </DropdownMenu>
      </Row>
      {showEditModal && (
        <Modal onClose={closeEditModal} heading="Rename Ad">
          <ModalBody>
            Edit name
            <Input
              name="name"
              value={tempName}
              onChange={handleNameChange}
              doNotUseInternalState
              error={false}
              label={false}
              id={item.id}
            />
          </ModalBody>
          <ModalFooter>
            <Button primary onClick={handleSave}>
              Save
            </Button>
            <Button secondary onClick={closeEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal onClose={closeDeleteModal} heading={`Delete ${item.name}`}>
          <ModalBody>
            <p>Are you sure you want to delete {item.name}? This action cannot be undone.</p>
            <p>Please confirm if you want proceed with deletion.</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={'red'} icon={'trash'} onClick={handleDelete}>
              Delete
            </Button>
            <Button secondary onClick={closeDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

FbAdtextTile.propTypes = {
  item: PropTypes.object,
  updateFbAdtext: PropTypes.func,
  removeFbAdtext: PropTypes.func,
};

export default FbAdtextTile;
