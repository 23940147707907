import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { Button, Row, Col, Heading } from '../index';
import { t } from '../../i18n';
import { CroppedImagePreview, MediaPreviewModal, AdSelectMediaModal } from './index';
import { singleMediaPlaces } from './meta';
import { getPreviewSize, buildMediaFromProps } from './utils';

const AdMediaUploadEditSingle = ({ options, data = [] }) => {
  const [selectedMedia, setSelectedMedia] = useState({});
  const [isNew, setIsNew] = useState(!data?.length);
  const [media, setMedia] = useState(buildMediaFromProps(data));
  const [showSelectMediaModal, setShowSelectMediaModal] = useState(false);
  const [showPreviewMediaModal, setShowPreviewMediaModal] = useState(false);

  const setNewMediaSource = () => {
    const originalMedia = {
      image_id: selectedMedia.id,
      source: selectedMedia.xlinkHref,
      crop: null,
      width: selectedMedia.width,
      height: selectedMedia.height,
    };

    const mediaArray = [];
    singleMediaPlaces.forEach(place => {
      mediaArray.push({ ...originalMedia, place, id: v4() });
    });
    setMedia(mediaArray);
    setShowSelectMediaModal(false);
    setTimeout(() => {
      setShowPreviewMediaModal(true);
    }, 500);
  };

  const setMediaSource = source => {
    setMedia(source);
    setIsNew(false);
    setShowPreviewMediaModal(false);
  };

  const removeAllMedia = () => {
    setMedia(media.map(m => ({ ...m, destroy: true })));
    setSelectedMedia({});
    setIsNew(true);
  };

  return (
    <React.Fragment>
      {media.map(mediaItem => (
        <React.Fragment key={mediaItem.id}>
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][id]`}
            value={mediaItem.line_id}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][place]`}
            value={mediaItem.place}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][id]`}
            value={mediaItem.asset_id}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][image_id]`}
            value={mediaItem.image_id}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][image_url]`}
            value={mediaItem.source}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][aspect]`}
            value={mediaItem.crop?.aspect || ''}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][crop_x]`}
            value={mediaItem.crop?.x || ''}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][crop_y]`}
            value={mediaItem.crop?.y || ''}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][crop_width]`}
            value={mediaItem.crop?.width || ''}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][image_asset_attributes][crop_height]`}
            value={mediaItem.crop?.height || ''}
          />
          <input
            type="hidden"
            name={`fb_adtext[${mediaItem.place}_adtext_line_attributes][_destroy]`}
            value={mediaItem.destroy}
          />
        </React.Fragment>
      ))}

      {isNew && (
        <Button kind="primary" icon="plus" onClick={() => setShowSelectMediaModal(true)}>
          {t('media.add_media')}
        </Button>
      )}

      {!isNew && (
        <>
          <Row>
            <Button kind="primary" icon="edit" onClick={() => setShowPreviewMediaModal(true)}>
              {t('media.edit_media', { default: 'Edit media' })}
            </Button>
            <Button kind="secondaryRed" icon="trash" onClick={removeAllMedia}>
              {t('media.remove_all_media', { default: 'Remove all media' })}
            </Button>
          </Row>
          <div className="mt-16">
            {media.map(mediaItem => {
              const size = getPreviewSize(mediaItem, 'single', 'small');
              return (
                <Row justifyBetween center key={mediaItem.id} className="mb-16">
                  <Col shrink>
                    <Row center>
                      <Col shrink>
                        <div className="MediaSingle-mediaPreviewWrapper">
                          <CroppedImagePreview image={mediaItem} size={size} style={{ borderRadius: '4px' }} />
                        </div>
                      </Col>
                      <Col shrink>
                        <Heading size="md" className="ml-16" spacing={0}>
                          {t(`media.place_${mediaItem.place}`)}
                        </Heading>
                      </Col>
                    </Row>
                  </Col>
                  <Col shrink>
                    <Button kind="secondary" icon="edit" onClick={() => setShowPreviewMediaModal(true)}>
                      {t('media.edit', { default: 'Edit' })}
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </div>
        </>
      )}

      {showSelectMediaModal && (
        <AdSelectMediaModal
          organizationId={options.organization_id}
          campaignSettingId={options.campaign_setting_id}
          selectedMedia={selectedMedia}
          onSelect={setSelectedMedia}
          onConfirm={setNewMediaSource}
          confirmButtonText={t('media.continue_to_crop', { default: 'Continue to crop' })}
          onClose={() => {
            setShowSelectMediaModal(false);
          }}
        />
      )}

      {showPreviewMediaModal && (
        <MediaPreviewModal
          media={media}
          onConfirm={setMediaSource}
          confirmButtonText={
            isNew ? t('media.insert_media', { default: 'Insert media' }) : t('media.save', { default: 'Save' })
          }
          onClose={() => setShowPreviewMediaModal(false)}
          onGoBack={() => {
            setShowPreviewMediaModal(false);
            setTimeout(() => {
              setShowSelectMediaModal(true);
            }, 500);
          }}
          showGoBackButton={isNew}
          organizationId={options.organization_id}
          campaignSettingId={options.campaign_setting_id}
          type="single"
        />
      )}
    </React.Fragment>
  );
};

AdMediaUploadEditSingle.propTypes = {
  options: PropTypes.any,
};

export default AdMediaUploadEditSingle;
