import React from 'react';
import PropTypes from 'prop-types';
import { Col, DropdownMenu, Link, Icon, Row, modalDataUrlOpener, Loader } from '../../../index';
import SmartSelect from '../../SmartSelect';
import { t } from '../../../../i18n';
import { filterDataObject, transformData } from '../utils';

export const CustomizeImageDropdown = ({ createNewLink, dropdownOpen, editLink, notify, value = null }) => (
  <React.Fragment>
    <DropdownMenu
      minWidth="176px"
      labelIcon={<Icon kind="image-type" size="18px" inheritColor />}
      label={t('react.fb_adtexts.customize_image')}
      onOpen={() => (typeof dropdownOpen === 'function' ? dropdownOpen(value) : null)}
      nowrap
    >
      {editLink && (
        <Link
          icon="edit"
          href="#"
          {...modalDataUrlOpener({
            url: editLink,
            size: 'fullwithGrid',
            notify,
          })}
        >
          {t('react.fb_adtexts.edit_custom_image')}
        </Link>
      )}
      <Link
        icon="plus"
        href="#"
        {...modalDataUrlOpener({
          url: createNewLink,
          size: 'fullwithGrid',
        })}
      >
        {t('react.fb_adtexts.create_new_variant')}
      </Link>
    </DropdownMenu>
  </React.Fragment>
);

const SingleImage = ({
  collection = [],
  data,
  dataSourceId,
  loading,
  newImageLink,
  notify,
  organizationId,
  selectedValue,
  setSelectedValue,
  ...rest
}) => {
  if (loading) {
    return (
      <Row center justifyCenter>
        <Loader />
        <input type="hidden" {...rest} value={selectedValue} />
      </Row>
    );
  }

  const transformedData = transformData(data);
  const selectedObject = filterDataObject(transformedData, selectedValue);
  const selectCollection = transformedData.length > 0 ? transformedData : collection;
  const selectKey = selectCollection
    .map(({ value, url }) => [value, url].join('-'))
    .sort()
    .join(',');

  return (
    <Row center>
      <Col width="278px">
        <SmartSelect
          {...rest}
          key={[selectedValue, selectKey].join(';')}
          className="SmartSelect--imageSelector"
          value={selectedValue}
          isLoading={loading}
          collection={selectCollection}
          dataSourceId={dataSourceId}
          organizationId={organizationId}
          onChange={({ target: { value } }) => setSelectedValue(value)}
        />
      </Col>

      <Col width="176px">
        <CustomizeImageDropdown
          createNewLink={
            selectedObject?.duplicateCopyLink
              ? selectedObject.duplicateCopyLink
              : `${newImageLink}?image_layer=${selectedValue}`
          }
          editLink={selectedObject?.editLink ? selectedObject.editLink : null}
          notify={notify}
        />
      </Col>
    </Row>
  );
};

CustomizeImageDropdown.propTypes = {
  createNewLink: PropTypes.string,
  dropdownOpen: PropTypes.func,
  editLink: PropTypes.string,
  notify: PropTypes.string,
  value: PropTypes.string,
};

SingleImage.propTypes = {
  collection: PropTypes.array,
  data: PropTypes.array,
  dataSourceId: PropTypes.number,
  loading: PropTypes.bool,
  newImageLink: PropTypes.string,
  notify: PropTypes.string,
  organizationId: PropTypes.number,
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
};
export default SingleImage;
