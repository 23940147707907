/* eslint-disable react/sort-comp */
import React, { PureComponent, memo, useCallback, useEffect } from 'react';
import { uniqBy, debounce } from 'lodash';
import PropTypes from 'prop-types';
import cs from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { gql, useLazyQuery, NetworkStatus } from '@apollo/client';
import Select, { components } from 'react-select';
import { FixedSizeList } from 'react-window';

import { ConditionBadgeContent } from '../table/mapping/cells';
import { formatInteger, t } from '../../i18n';
import stripDiacritics from '../../utils/stripDiactritics';
import { Ellipsis, Button, Icon, Row, Col, listenOnModalChange, Text, cssVariables, Tooltip } from '../index';
import smartSelectStyles from './styles/SmartSelectStyles';
import SystemIcon from '../SystemIcon';
import DotIcon32 from '../../scss_stylesheets/images/dot_icon_32.png';

export const convertCollectionValue = value => {
  if (typeof value === 'string' || typeof value === 'number') {
    return { value, label: value };
  }

  if (Array.isArray(value)) {
    const [val, label, data = {}] = value;
    return { data, value: val, label };
  }
  return value;
};

const getOptionsFromCollection = collection => collection.map(convertCollectionValue);

export const Asset = ({ name, url }) => (
  <React.Fragment>
    <Row center>
      <Col shrink>
        <img src={url} alt={name} />
      </Col>
      <Col grow>
        <Ellipsis>{name}</Ellipsis>
      </Col>
    </Row>
  </React.Fragment>
);
Asset.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

export const FontFamilies = ({ label }) => (
  <Row center className="cursor-pointer">
    <Col shrink>
      <span style={{ fontFamily: label }}>{label}</span>
    </Col>
  </Row>
);

FontFamilies.propTypes = {
  label: PropTypes.string,
};

export const FBLocation = ({ label }) => {
  const { name, type, country_code } = JSON.parse(label);
  return (
    <Row center>
      <Col shrink>[{country_code}]</Col>
      <Col grow>
        <Ellipsis>{name}</Ellipsis>
      </Col>
      <Col shrink>{type}</Col>
    </Row>
  );
};
FBLocation.propTypes = {
  label: PropTypes.string,
};

export const FBTargeting = ({ label }) => {
  const { name, type, audience_size_lower_bound, audience_size_upper_bound } = JSON.parse(label);
  return (
    <Row center>
      <Col grow>
        <Ellipsis>{name}</Ellipsis>
      </Col>
      <Col shrink>{type}</Col>
      <Col shrink>
        ({formatInteger(audience_size_lower_bound)} - {formatInteger(audience_size_upper_bound)})
      </Col>
    </Row>
  );
};
FBTargeting.propTypes = {
  label: PropTypes.string,
};

export const Account = ({ label, data }) => (
  <span>
    {label} {data && <small className="ml-16">({[data.system_id, data.currency].filter(x => !!x).join(', ')})</small>}
  </span>
);
Account.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
};

export const AccountRich = ({ label, supportiveText, accountIcon }) => (
  <Row center>
    {accountIcon && (
      <Col shrink>
        <Icon color={cssVariables.iconSubtle} kind={accountIcon} size="20px" />
      </Col>
    )}
    <Col>
      <Text className="SmartSelect--AccountRich__accountName" tag="div">
        {label}
      </Text>
      <Text className="SmartSelect--AccountRich__supportiveText" tag="div">
        {supportiveText}
      </Text>
    </Col>
  </Row>
);

Account.AccountRich = {
  accountName: PropTypes.string,
  supportiveText: PropTypes.object,
  accountIcon: PropTypes.string,
};

// TODO - REMOVE THIS, SHOULD BE REPLACE BY AccountRich
export const FBAccount = ({ label, data }) => (
  <>
    <div className="SmartSelect--option-text-bold">{label}</div>
    {data && (
      <div className="SmartSelect--option-text-regular">
        {[data.system_id, data.currency].filter(x => !!x).join(', ')}
      </div>
    )}
  </>
);

FBAccount.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
};

export const Label = ({ label, data }) => (
  <Row alignCenter>
    <Col shrink>
      <span
        style={{ background: data.color, width: '16px', height: '16px', borderRadius: '8px', display: 'inline-block' }}
      >
        {' '}
      </span>
    </Col>
    <Col shrink>{label}</Col>
  </Row>
);
Label.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
};

export const Description = ({ label, data }) => (
  <span>
    <div>{label}</div>
    {data && <small className="SmartSelect-notShowInSelected SmartSelect-description">{data.description}</small>}
  </span>
);
Description.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
};

export const FBProductCatalogOrFeedMapping = ({ campaignName, catalogName, catalogId, businessName }) => (
  <Row>
    <Col shrink>
      {campaignName ? (
        <img src={DotIcon32} style={{ width: '14px', height: '14px' }} alt="Dotidot feed" />
      ) : (
        <Icon kind="facebook-round" color="#1877f2" />
      )}
    </Col>
    <Col shrink>
      <span className="pl-8 pr-16">{catalogName}</span>
    </Col>
    <Col grow>
      <small>
        <Ellipsis>{campaignName || businessName}</Ellipsis>
      </small>
    </Col>
    {catalogId && catalogId.length > 0 && (
      <Col shrink>
        <small>(ID: {catalogId})</small>
      </Col>
    )}
  </Row>
);
FBProductCatalogOrFeedMapping.propTypes = {
  campaignName: PropTypes.string,
  catalogName: PropTypes.string,
  catalogId: PropTypes.string,
  businessName: PropTypes.string,
};

export const GMCDataFeedOrFeedMapping = ({ campaignName, dataFeedName, dataFeedId, gmcAccountName, value }) => (
  <Row center style={{ height: '36px' }}>
    <Col shrink>
      {campaignName && <img src={DotIcon32} style={{ width: '20px', height: '20px' }} alt="Dotidot feed" />}
      {!campaignName && value !== 'create-new' && <Icon kind="feed-export-gmc" color="#608DEE" size={20} />}
      {value === 'create-new' && <Icon kind="enrich-gmc" size={20} />}
    </Col>
    <Col grow padding="s">
      <Row className="mw-100">
        <Col grow>
          <Row className="mw-100">
            {campaignName ? (
              <React.Fragment>
                <Col grow wrap>
                  <Ellipsis>{campaignName}</Ellipsis>
                </Col>
              </React.Fragment>
            ) : (
              <Col grow wrap>
                <Ellipsis>{dataFeedName}</Ellipsis>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {(campaignName || gmcAccountName || dataFeedId) && (
        <Row className="mw-100">
          {campaignName && (
            <Col shrink wrap>
              <Ellipsis>
                <Text color="gray" size="sm">
                  {dataFeedName}
                </Text>
              </Ellipsis>
            </Col>
          )}
          <Col shrink wrap style={{ maxWidth: '30%' }}>
            <Ellipsis>
              <Text color="gray" size="sm">
                {gmcAccountName}
              </Text>
            </Ellipsis>
          </Col>
          {dataFeedId && dataFeedId.length > 0 && (
            <Col shrink>
              <Text color="gray" size="sm">
                (ID: {dataFeedId})
              </Text>
            </Col>
          )}
        </Row>
      )}
    </Col>
  </Row>
);
GMCDataFeedOrFeedMapping.propTypes = {
  campaignName: PropTypes.string,
  dataFeedName: PropTypes.string,
  dataFeedId: PropTypes.string,
  gmcAccountName: PropTypes.string,
  value: PropTypes.string,
};
export const FBProductSet = ({ label, conditions }) => (
  <div>
    <span className="pr-16">{label}</span>
    <ConditionBadgeContent>{conditions}</ConditionBadgeContent>
  </div>
);
FBProductSet.propTypes = {
  label: PropTypes.string,
  conditions: PropTypes.array,
};

export const FBResource = ({ label, description }) => (
  <span>
    <div>{label}</div>
    {description && <small className="SmartSelect-description">{description}</small>}
  </span>
);
FBResource.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
};

export const FBAdtext = ({ label, description }) => (
  <span>
    <div>{label}</div>
    {description && <small className="SmartSelect-description">{description}</small>}
  </span>
);
FBAdtext.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
};

export const OptionWithCounter = ({ label, count }) => (
  <Row>
    <Col grow>{label}</Col>
    <Col shrink>
      <small className="ml-16">({count})</small>
    </Col>
  </Row>
);
OptionWithCounter.propTypes = {
  label: PropTypes.string,
  count: PropTypes.object,
};

export const CustomAudience = ({ id, name, subtype, deliveryStatus, description, hasStatusBadgeHidden }) => (
  <React.Fragment>
    <Row>
      <Col grow>
        <Ellipsis>{name}</Ellipsis>
      </Col>
      {/* <Col shrink className="SmartSelect-notShowInSelected">
        <small className="ml-16">
          <Icon kind="monitoring" /> {formatInteger(approximateCount || 0)}
        </small>
      </Col> */}
      {!hasStatusBadgeHidden && (
        <Col shrink className="SmartSelect-notShowInSelected">
          <span
            className={cs('Badge', 'Badge--medium', {
              'Badge--green': deliveryStatus === 200,
              'Badge--gray': deliveryStatus !== 200,
            })}
          />
        </Col>
      )}
    </Row>
    <Row className="mt-0 SmartSelect-notShowInSelected SmartSelect-description">
      <Col shrink>
        <small>Id: {id}</small>
      </Col>
      {subtype && (
        <Col shrink>
          <small>{subtype}</small>
        </Col>
      )}
      <Col shrink>
        <small>{description}</small>
      </Col>
    </Row>
  </React.Fragment>
);
CustomAudience.propTypes = {
  // approximateCount: PropTypes.number,
  deliveryStatus: PropTypes.number,
  description: PropTypes.string,
  hasStatusBadgeHidden: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  subtype: PropTypes.string,
};

export const ItemGroup = ({ label, conditions }) => (
  <div>
    <span className="pr-16">{label}</span>
    <ConditionBadgeContent>{conditions}</ConditionBadgeContent>
  </div>
);
ItemGroup.propTypes = {
  label: PropTypes.string,
  conditions: PropTypes.array,
};

const customOptionMapping = {
  Account,
  Label,
  Description,
  FBResource,
  FBProductSet,
  FBAdtext,
  FBProductCatalogOrFeedMapping,
  GMCDataFeedOrFeedMapping,
  Asset,
  FBLocation,
  FBTargeting,
  FontFamilies,
  ItemGroup,
  FBAccount,
  AccountRich,
};

const trimString = str => str.replace(/^\s+|\s+$/g, '');

const filterOption = (option, rawInput) => {
  const input = stripDiacritics(trimString(rawInput).toLowerCase());
  const candidate = stripDiacritics(
    trimString(
      `${option.data.originalLabel || option.data.label} ${option.data.value} ${Object.values(
        option.data.data || {}
      ).join(' ')}`
    ).toLowerCase()
  );

  return candidate.indexOf(input) > -1 || !input.split(/\s+/).find(inp => candidate.indexOf(inp) === -1);
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon kind="chevron-down" inheritColor size="20px" />
  </components.DropdownIndicator>
);

const CustomClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon kind="close-outline-squared" inheritColor size="16px" />
  </components.ClearIndicator>
);

const CustomClearIndicatorWithCopy = props => {
  const copyToClipboard = () => {
    const text = props
      .getValue()
      .map(item => item.value)
      .join('\n');
    window.navigator.clipboard.writeText(text).then(() => {
      new window.NotificationCenter().show_success(t('views.copied_to_clipboard', { default: 'Copied to clipboard' }));
    });
  };

  return (
    <div className="d-flex align-items-center flex-gap-8 mr-4">
      <components.ClearIndicator {...props}>
        <Icon kind="close-outline-squared" inheritColor size="16px" />
      </components.ClearIndicator>
      <Button size="small" kind="secondaryGray" icon="duplicate-alt" onClick={copyToClipboard} onlyIcon />
    </div>
  );
};

const CustomMultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <Icon kind="close" inheritColor size="14px" />
  </components.MultiValueRemove>
);

const CustomMultiValue = props => (
  <React.Fragment>
    <components.MultiValue {...props}>
      {props.data.icon && <Icon kind={props.data.icon} inheritColor size="14px" className="mr-4" />}
      {props.data.systemIcon && <SystemIcon size={'14px'} system={props.data.systemIcon} />}
      {!props.data.icon && !props.data.systemIcon && props.children}
    </components.MultiValue>
    {props.separator && (
      <Text color="softGray" size="sm">
        {props.separator}
      </Text>
    )}
  </React.Fragment>
);

CustomMultiValue.propTypes = {
  data: PropTypes.object,
  children: PropTypes.any,
  separator: PropTypes.bool,
};

const CustomNoOptionsMessage = (customNoOptionsMessage, options) => props => (
  <components.NoOptionsMessage {...props}>
    <span className={cs({ 'd-block Text--error text-left Text--bold': options?.isError })}>
      {options?.isError && <Icon kind="danger" size={16} className="mr-8" color="inherit" />}
      {customNoOptionsMessage || t('react.smart_select.no_options')}
    </span>
  </components.NoOptionsMessage>
);

const CustomOption = props => (
  <components.Option {...props}>
    {props.data.icon && <Icon kind={props.data.icon} inheritColor size="20px" className="mr-4" />}
    {props.data.systemIcon && <SystemIcon size={'20px'} system={props.data.systemIcon} />}
    {props.children}
  </components.Option>
);

CustomOption.propTypes = {
  data: PropTypes.object,
  children: PropTypes.any,
};

const height = 35;

class ScrollWindowMenuList extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    children: PropTypes.any,
    maxHeight: PropTypes.number,
    getValue: PropTypes.func,
  };

  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <FixedSizeList
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    );
  }
}

const getOptionComponent = optionRenderer => {
  if (typeof optionRenderer === 'string') return customOptionMapping[optionRenderer];
  return optionRenderer;
};

const decorateOptionWithFocus = onFocusChange => props => {
  useEffect(() => {
    if (props.isFocused) {
      onFocusChange(props);
    }
    // eslint-disable-next-line react/prop-types
  }, [props.isFocused]);
  return <CustomOption {...props} />;
};

class SmartSelect extends PureComponent {
  static propTypes = {
    asyncLoadOptions: PropTypes.bool,
    asyncOptions: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    collection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
    creatable: PropTypes.bool,
    creatableAsList: PropTypes.bool,
    creatableOptions: PropTypes.object,
    customNoOptionsMessage: PropTypes.string,
    disabled: PropTypes.bool,
    doNotUseInternalState: PropTypes.bool,
    enableHidding: PropTypes.bool,
    enableCopyValue: PropTypes.bool,
    exactMatch: PropTypes.bool,
    horizontaly: PropTypes.bool,
    id: PropTypes.string.isRequired,
    initWithoutSelectedValue: PropTypes.bool,
    inputLabel: PropTypes.string,
    inputIcon: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasSelectedValueFullWidth: PropTypes.bool,
    loadOptions: PropTypes.func,
    multiple: PropTypes.bool,
    multiValueSeparator: PropTypes.string,
    sendAsMultiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    selectOnModalChange: PropTypes.string,
    onMenuOpen: PropTypes.func,
    onItemFocus: PropTypes.func,
    onMenuClose: PropTypes.func,
    optionRenderer: PropTypes.oneOf(Object.keys(customOptionMapping)),
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    testId: PropTypes.string,
    value: PropTypes.any.isRequired,
    optionsWithIcon: PropTypes.bool,
    optional: PropTypes.bool,
    tooltip: PropTypes.string,
    assistiveText: PropTypes.string,
    status: PropTypes.oneOf(['normal', 'warning', 'error', 'success']),
  };

  static defaultProps = {
    asyncOptions: [],
    collection: [],
    doNotUseInternalState: false,
    required: false,
    multiple: false,
  };

  componentDidMount() {
    if (this.props.selectOnModalChange) {
      this.removeListenOnModalChange = listenOnModalChange((name, data) => {
        if (name === this.props.selectOnModalChange) {
          let newItem = { ...data, label: data.name, value: data.id };
          if (this.props.optionRenderer) {
            const LabelComponent = getOptionComponent(this.props.optionRenderer);
            newItem = {
              ...newItem,
              originalLabel: newItem.label,
              label: <LabelComponent {...newItem} />,
            };
          }

          if (this.props.multiple) {
            this.setState({
              options: [...this.state.options, newItem],
              value: [...this.state.value, newItem],
            });
          } else {
            this.setState({
              options: [...this.state.options, newItem],
              value: newItem,
            });
          }
          this.notifyHiddenFiledAboutChange();
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.removeListenOnModalChange) {
      this.removeListenOnModalChange();
    }
  }

  getOptions = () => {
    const { collection, optionRenderer } = this.props;

    const LabelComponent = getOptionComponent(optionRenderer);

    return getOptionsFromCollection(collection).map(option => {
      if (optionRenderer) {
        return {
          ...option,
          originalLabel: option.label,
          label: <LabelComponent {...option} />,
        };
      }
      return option;
    });
  };

  getDefaultValue = () => {
    let options = this.getOptions();
    const value =
      (this.props.multiple && typeof this.props.value === 'string' && this.props.value.startsWith('[')
        ? JSON.parse(this.props.value)
        : this.props.value) || null;

    if (this.props.creatable && this.props.multiple && value) {
      const newOptions = (value instanceof Array ? value : [value])
        .filter(v => v && v !== 'null' && v.length > 0 && !options.find(o => o.value === v))
        .map(v => ({ __isNew__: true, value: v, label: v }));
      options = options.concat(newOptions);
    }

    if (this.props.creatable && !this.props.multiple && value) {
      const newOptions = [{ __isNew__: true, value, label: value }];
      options = options.concat(newOptions);
    }

    if (this.props.asyncLoadOptions && this.props.multiple && value) {
      const newOptions = value.map(v => ({ value: v, label: v }));
      options = options.concat(newOptions);
    }

    if (this.props.multiple && value) {
      const sortedOptionsByLengthyFirst = [...options].sort(
        (a, b) => b.value?.toString()?.length - a.value?.toString()?.length
      );

      return (Array.isArray(value) ? value : [value])
        .map(v =>
          sortedOptionsByLengthyFirst.find(option =>
            this.props.exactMatch
              ? v?.toString() === option.value
              : v?.toString().indexOf(option.value) !== -1 || v?.toString().indexOf(option.value?.toString()) !== -1
          )
        )
        .filter(o => !!o);
    }

    if (!this.props.isClearable && !this.props.initWithoutSelectedValue) {
      return (
        options.find(option => value === option.value || value?.toString() === option.value?.toString()) || options[0]
      );
    }

    return options.find(option => value === option.value || value?.toString() === option.value?.toString());
  };

  state = {
    value: this.getDefaultValue(),
    options: this.getOptions(),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.asyncOptions !== this.props.asyncOptions) {
      const { value } = this.state;
      const arrayValue = (value instanceof Array ? value : [value]).filter(v => !!v);
      // eslint-disable-next-line array-callback-return
      nextProps.asyncOptions.map(newOption => {
        const index = arrayValue.findIndex(v => v.value === newOption.value);
        if (index !== -1) {
          arrayValue[index] = newOption;
        }
      });
    }
  }

  notifyHiddenFiledAboutChange = () => {
    if (this.wrapper) {
      const hiddenField = this.wrapper.querySelector('input[type="hidden"]');
      if (hiddenField) {
        hiddenField.dispatchEvent(new window.Event('change', { bubbles: true }));
      } else {
        const form = this.wrapper.closest('form');
        if (form) {
          form.dispatchEvent(new window.Event('change', { bubbles: true }));
        }
      }
    }
  };
  handleChange = value => {
    this.setState({ value });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: this.props.multiple ? (value || []).map(i => i.value) : value.value } });
    }
    this.notifyHiddenFiledAboutChange();
  };

  setRef = el => {
    if (el) {
      this.wrapper = el;
    }
  };

  handleInputChange = searchText => {
    const { creatable, creatableAsList, creatableOptions } = this.props;

    if (creatable && creatableOptions?.getValidationError) {
      this.setState({ searchError: creatableOptions.getValidationError(searchText)?.message });
    }

    if (creatable && creatableAsList && searchText.endsWith(',')) {
      if (searchText.trim().length > 1) {
        const value = searchText.trim().slice(0, -1);
        this.handleChange([...this.state.value, { __isNew__: true, value, label: value }]);
      }
      setTimeout(
        () =>
          this.selectInputRef.onInputChange('', {
            action: 'menu-close',
            prevInputValue: '',
          }),
        10
      );
    }

    if (typeof this.props.loadOptions === 'function') {
      this.props.loadOptions(searchText);
    }
  };

  initPasteEvent = el => {
    setTimeout(() => {
      if (el && el.select && el.select.select && el.select.select.inputRef) {
        this.selectInputRef = el;
        window.XSADSADAS = this.selectInputRef;

        el.select.select.inputRef.addEventListener('paste', event => {
          const paste = (event.clipboardData || window.clipboardData).getData('text');
          const pastedItems = paste
            .split(this.props.creatableAsList ? /,|\n/ : '\n')
            .filter(s => `${s}`.trim().length > 0);

          if (pastedItems.length > 1) {
            event.preventDefault();
            this.handleChange(
              uniqBy(
                [...this.state.value, ...pastedItems.map(value => ({ __isNew__: true, value, label: value }))].filter(
                  i => !!i
                ),
                e => e && e.value
              )
            );
          }
        });
      }
    }, 100);
  };

  render() {
    const {
      asyncOptions,
      className,
      creatable,
      creatableAsList,
      creatableOptions,
      customNoOptionsMessage,
      disabled,
      doNotUseInternalState,
      enableCopyValue,
      enableHidding,
      id,
      optionRenderer,
      inputLabel,
      isClearable,
      isLoading,
      hasSelectedValueFullWidth,
      multiple,
      name,
      multiValueSeparator,
      onBlur,
      onMenuOpen,
      onMenuClose,
      onItemFocus,
      placeholder,
      required,
      testId,
      sendAsMultiple,
      inputIcon,
      optionsWithIcon,
      optional,
      tooltip,
      assistiveText,
      status = 'normal',
    } = this.props;
    const { options } = this.state;
    const selected = doNotUseInternalState ? this.getDefaultValue() : this.state.value;
    const SelectComponent = creatable ? CreatableSelect : Select;

    const hide = selected?.length === 0 && options?.length === 0 && enableHidding;

    return (
      <div className={cs({ 'd-none': hide }, className)} ref={this.setRef} data-test-id="SmartSelect">
        {id && inputLabel && (
          <Row center style={{ marginBottom: '6px' }}>
            <Col shrink>
              <label htmlFor={id}>
                <Text medium style={{ marginRight: '4px' }}>
                  {inputLabel}
                </Text>
                {optional && (
                  <Text style={{ color: cssVariables.textSubtle, marginRight: '4px' }}>
                    ({t('react.smart_select.optional', { default: 'optional' })})
                  </Text>
                )}
                {required && (
                  <Text medium style={{ color: cssVariables.interactiveAttentionDefault }}>
                    *
                  </Text>
                )}
              </label>
            </Col>
            {tooltip && (
              <Col shrink>
                <Tooltip text={tooltip}>
                  <Icon color={cssVariables.textGhost} kind="info-circle-fill" size="16px" />
                </Tooltip>
              </Col>
            )}
          </Row>
        )}
        <div
          className={cs('SmartSelectWrapper', {
            'SmartSelectWrapper--withIcon': inputIcon,
            'SmartSelectWrapper--hasValue': selected,
            'SmartSelectWrapper--optionsWithIcon': optionsWithIcon,
            [`SmartSelectWrapper--status__${status}`]: status,
          })}
        >
          {inputIcon && (
            <Icon kind={inputIcon} color={cssVariables.iconSubtle} size="20px" className="SmartSelect--inputIcon" />
          )}
          <SelectComponent
            allowCreateWhileLoading={creatable}
            className={cs('SmartSelect', `SmartSelect--${optionRenderer}`, {
              required,
              'SmartSelect--selectedValueFullWidth': hasSelectedValueFullWidth,
              'SmartSelect--list': creatableAsList,
            })}
            classNamePrefix="SmartSelect"
            closeMenuOnSelect={!multiple}
            components={{
              DropdownIndicator: enableCopyValue ? null : CustomDropdownIndicator,
              NoOptionsMessage: CustomNoOptionsMessage(this.state.searchError || customNoOptionsMessage, {
                isError: !!creatableOptions?.getValidationError,
              }),
              ClearIndicator: enableCopyValue ? CustomClearIndicatorWithCopy : CustomClearIndicator,
              Menu: creatableAsList ? () => null : components.Menu,
              MultiValueRemove: CustomMultiValueRemove,
              MultiValue: props => <CustomMultiValue separator={multiValueSeparator} {...props} />,
              MenuList: options.length < 1000 ? components.MenuList : ScrollWindowMenuList,
              Option: typeof onItemFocus === 'function' ? decorateOptionWithFocus(onItemFocus) : CustomOption,
            }}
            data-test-id={testId}
            filterOption={filterOption}
            hideSelectedOptions={false}
            id={id}
            isClearable={isClearable}
            isDisabled={disabled}
            isLoading={isLoading}
            isMulti={multiple}
            isValidNewOption={paramas => !creatableOptions?.getValidationError(paramas)}
            name={(multiple || sendAsMultiple) && name.indexOf('[]') === -1 ? `${name}[]` : name}
            onBlur={onBlur}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            options={uniqBy(options.concat(asyncOptions), v => (v && typeof v === 'object' ? v.value.toString() : v))}
            placeholder={placeholder}
            ref={multiple ? this.initPasteEvent : () => {}}
            styles={smartSelectStyles}
            value={selected}
          />
          {assistiveText && (
            <Row center padding="s" style={{ marginTop: '6px' }}>
              <Col shrink>
                {status === 'normal' && <Icon color={cssVariables.iconSubtle} kind="info-circle" size="16px" />}
                {status === 'warning' && <Icon color={cssVariables.statusWarningDefault} kind="danger" size="16px" />}
                {status === 'success' && (
                  <Icon color={cssVariables.statusPositiveDefault} kind="circle-check" size="16px" />
                )}
                {status === 'error' && (
                  <Icon color={cssVariables.statusAttentionDefault} kind="error-circle" size="16px" />
                )}
              </Col>
              <Col shrink>
                <Text className={cs({ [`SmartSelect--assistiveText__${status}`]: status })}>{assistiveText}</Text>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const QUERY = gql`
  query ConditionValueAutocomplete(
    $search: String!
    $field: String!
    $dataSourceId: BigInt
    $campaignSettingId: BigInt
    $feedMappingId: BigInt
    $elasticQuery: String
  ) {
    dataFieldOptions(
      search: $search
      field: $field
      dataSourceId: $dataSourceId
      feedMappingId: $feedMappingId
      campaignSettingId: $campaignSettingId
      elasticQuery: $elasticQuery
    ) {
      key
      docCount
    }
  }
`;

const AUTOCOMPLETE = {
  additionalSourceAutocomplete: {
    map: data => (data?.organization?.additionalSourceAutocompleteValues || []).map(value => ({ label: value, value })),
    query: gql`
      query AdditionalSourceAutocompleteDimensions(
        $organizationId: BigInt!
        $systemId: String!
        $field: String!
        $additionalSourceImporter: String!
      ) {
        organization(id: $organizationId) {
          additionalSourceAutocompleteValues(
            field: $field
            systemId: $systemId
            additionalSourceImporter: $additionalSourceImporter
          )
        }
      }
    `,
  },
  adwordsLocations: {
    map: data => data?.adwordsLocations || [],
    query: gql`
      query AdwordsLocationAutocomplete($search: String!) {
        adwordsLocations(search: $search) {
          value
          label
        }
      }
    `,
  },
  fbGeoLocations: {
    map: data =>
      (data?.organization?.campaignSetting?.fbGeoLocations || []).map(({ value, label }) => ({
        value,
        originalLabel: label,
        label: <FBLocation label={label} />,
      })),
    query: gql`
      query FBLocationAutocomplete($search: String!, $campaignSettingId: BigInt!, $organizationId: BigInt!) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            fbGeoLocations(search: $search) {
              value
              label
            }
          }
        }
      }
    `,
  },
  fbDetailedTargetings: {
    map: data =>
      (data?.organization?.campaignSetting?.fbDetailedTargetings || []).map(({ value, label }) => ({
        value,
        originalLabel: label,
        label: <FBTargeting label={label} />,
      })),
    query: gql`
      query FBDetailedTargetingAutocomplete($search: String!, $campaignSettingId: BigInt!, $organizationId: BigInt!) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            fbDetailedTargetings(search: $search) {
              value
              label
            }
          }
        }
      }
    `,
  },
};

const AsyncSmartSelect = memo(({ loadOptionsFilter, autocomplete, fetchPolicy = 'cache-first', ...rest }) => {
  if (!loadOptionsFilter && !autocomplete) {
    return <SmartSelect {...rest} />;
  }
  const autocompleteKey = autocomplete || loadOptionsFilter?.autocomplete;

  const autocompleteQuery = autocompleteKey ? AUTOCOMPLETE[autocompleteKey].query : QUERY;

  const [findOptions, { data, called, networkStatus }] = useLazyQuery(autocompleteQuery, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });
  const loading = networkStatus !== NetworkStatus.ready;

  let asyncOptions = [];
  if (!autocompleteKey) {
    asyncOptions = ((data && data.dataFieldOptions) || []).map(({ key, docCount }) => ({
      value: key,
      originalLabel: key,
      label: <OptionWithCounter label={key} count={docCount} />,
    }));
  } else {
    asyncOptions = AUTOCOMPLETE[autocompleteKey].map(data);
  }
  if (!loading && !called) {
    findOptions({ variables: { ...loadOptionsFilter, search: '' } });
  }

  const autocompleteSearch = useCallback(search => findOptions({ variables: { ...loadOptionsFilter, search } }), []);
  const debouncedSearch = useCallback(debounce(autocompleteSearch, 500), []);

  return (
    <SmartSelect
      isLoading={loading}
      asyncOptions={asyncOptions}
      loadOptions={loadOptionsFilter.withoutSearch ? () => {} : debouncedSearch}
      {...rest}
    />
  );
});

AsyncSmartSelect.propTypes = {
  loadOptionsFilter: PropTypes.object,
  autocomplete: PropTypes.string,
  fetchPolicy: PropTypes.oneOf(['cache-first', 'network-only', 'cache-only', 'no-cache', 'standby']),
};

export default AsyncSmartSelect;
