import { t } from '../../i18n';

export const conversionLocationOptions = [
  {
    title: t('react.fb_aplus.conversion_location.website', { default: 'Website' }),
    description: t('react.fb_aplus.conversion_location.website_description', {
      default: 'Drive sales and conversions on your website',
    }),
    value: 'website',
    icon: 'credit-card',
  },
  {
    title: t('react.fb_aplus.conversion_location.website_and_app', { default: 'Website and app' }),
    description: t('react.fb_aplus.conversion_location.website_and_app_description', {
      default: 'Drive sales and conversions on your website or app',
    }),
    value: 'website_and_app',
    icon: 'dimensions',
    disabled: true,
    additionalContent: {
      text: t('react.fb_aplus.conversion_location.additional_text', { default: "If you're interested, let us know!" }),
      ctaText: t('react.fb_aplus.conversion_location.cta_text', { default: 'Notify us' }),
      onClick: () => window.open('https://tally.so/r/mR4W0j', '_blank'),
    },
  },
];

export const adFormatOptions = [
  {
    title: t('react.fb_aplus.ad_format.single_image', { default: 'Single image' }),
    description: t('react.fb_aplus.ad_format.single_image_description', {
      default: 'One image as the main message of the ad',
    }),
    value: 'single_image_or_video',
  },
  {
    title: t('react.fb_aplus.ad_format.carousel', { default: 'Carousel' }),
    description: t('react.fb_aplus.ad_format.carousel_description', {
      default: '2 or more scrollable images or videos',
    }),
    value: 'carousel_images_multi_items',
  },
  {
    title: t('react.fb_aplus.ad_format.collection', { default: 'Collection' }),
    description: t('react.fb_aplus.ad_format.collection_description', {
      default: 'Group of items that opens into a fullscreen mobile experience',
    }),
    value: 'collection',
    disabled: true,
    additionalContent: {
      text: t('react.fb_aplus.ad_format.additional_text', { default: "If you're interested, let us know!" }),
      ctaText: t('react.fb_aplus.ad_format.cta_text', { default: 'Notify us' }),
      onClick: () => window.open('https://tally.so/r/nrley5', '_blank'),
    },
  },
  {
    title: t('react.fb_aplus.ad_format.video', { default: 'Video' }),
    description: t('react.fb_aplus.ad_format.video_description', {
      default: 'One video as the main message of the ad',
    }),
    value: 'video',
    disabled: true,
    additionalContent: {
      text: t('react.fb_aplus.ad_format.additional_text', { default: "If you're interested, let us know!" }),
      ctaText: t('react.fb_aplus.ad_format.cta_text', { default: 'Notify us' }),
      onClick: () => window.open('https://tally.so/r/mYOGk0', '_blank'),
    },
  },
];
