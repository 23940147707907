import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { SectionHeading, Row, Col, Icon, Text, cssVariables } from '../index';
import UsedFieldGraph from './UsedFieldGraph';
import { t } from '../../i18n';

const HEADER_STATE = {
  positive: {
    color: cssVariables.statusPositiveDefault,
    background: cssVariables.statusPositiveFaint,
    delimiter: cssVariables.statusPositiveMuted,
  },
  alert: {
    color: cssVariables.statusAttentionDefault,
    background: cssVariables.statusAttentionFaint,
    delimiter: cssVariables.statusAttentionMuted,
  },
  warning: {
    color: cssVariables.statusWarningDefault,
    background: cssVariables.statusWarningFaint,
    delimiter: cssVariables.statusWarningMuted,
  },
  monochromatic: {
    color: cssVariables.textDefault,
    specialColor: cssVariables.textSubtle,
    delimiter: cssVariables.interfaceOutlineBold,
  },
};

const getHEaderState = percents => {
  if (percents > 99) {
    return HEADER_STATE.positive;
  } else if (percents > 50) {
    return HEADER_STATE.warning;
  }
  return HEADER_STATE.alert;
};

const Fieldset = ({
  collapsable = false,
  colourless = false,
  description,
  heading,
  inputGroups = [],
  stickyHeaderColumn,
  withoutGraph = false,
  withoutGraphDescription = false,
  dataTestId,
}) => {
  const divRef = useRef(null);
  const [fieldCount, setFieldCount] = useState(0);
  const [filledFieldCount, setFilledFieldCount] = useState(0);
  const [showFields, setShowFields] = useState(false);
  const usageFieldPercent = (filledFieldCount * 100) / fieldCount;
  const headerState = colourless ? HEADER_STATE.monochromatic : getHEaderState(usageFieldPercent);

  useEffect(() => {
    const interval = setInterval(() => {
      if (divRef.current) {
        const visibleFields = Array.from(
          divRef.current
            .closest('.js-react-feed-export-fields-container')
            .querySelectorAll('.js-react-feed-export-fields .Input')
        ).filter(field => !field.disabled && !field.closest('.hidden[data-form-condition]'));

        const nonGroupVisibleFields = visibleFields.filter(
          field =>
            !inputGroups?.reduce(
              (acc, item) =>
                field.querySelector('input').name.includes(item) ||
                field.querySelector('.SmartSelect > input')?.name.includes(item) ||
                acc,
              false
            )
        );

        const groupedFilledFields = inputGroups?.map(groupName =>
          visibleFields.filter(field => {
            const inputEle = field.querySelector('input') || field.querySelector('.SmartSelect > input');
            return inputEle.name.includes(groupName) && inputEle.value.length > 0;
          })
        );

        setFilledFieldCount(
          nonGroupVisibleFields.filter(
            field =>
              field.querySelector('input').value.length > 0 ||
              field.querySelector('.SmartSelect > input')?.value.length > 0
          ).length + (groupedFilledFields?.reduce((acc, item) => (item.length > 0 ? acc + 1 : acc + 0), 0) || 0)
        );

        setFieldCount(nonGroupVisibleFields.length + (groupedFilledFields?.length || 0));
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const container = divRef.current
      .closest('.js-react-feed-export-fields-container')
      .querySelector('.js-react-feed-export-fields');
    if (collapsable) {
      if (showFields) {
        container.classList.remove('hidden');
      } else {
        container.classList.add('hidden');
      }
    }
  }, [showFields, collapsable]);

  return (
    <div
      className="NewLayout-stickyBar NewLayout-stickyBar--smallShadow js-newLayout-stickyBar border-top-right-radius-8 border-top-left-radius-8"
      ref={divRef}
    >
      <div
        className={cs('pt-16 ph-16 negative-mh-16 border-top-right-radius-8 border-top-left-radius-8', {
          'cursor-pointer': collapsable,
          'pb-16': !collapsable || showFields,
        })}
        style={{ background: headerState.background, color: headerState.color }}
        onClick={collapsable ? () => setShowFields(!showFields) : undefined}
        data-test-id={dataTestId}
      >
        <Row center>
          <Col grow>
            <SectionHeading spacing={4} className="Heading--inheritColor">
              {heading}
            </SectionHeading>
            <Text className={cs({ 'Text--inheritColor': !colourless, 'Text--gray': colourless })}>{description}</Text>
          </Col>
          <Col shrink>
            <UsedFieldGraph
              color={headerState.specialColor || headerState.color}
              totalCount={fieldCount}
              filledCount={filledFieldCount}
              withoutGraph={withoutGraph}
              withoutGraphDescription={(collapsable && filledFieldCount === 0) || withoutGraphDescription}
            />
          </Col>
          {collapsable && (
            <Col shrink className="ml-16">
              <Icon
                kind="chevron-down"
                size="20px"
                color={cssVariables.interactiveSecondaryMuted}
                style={{ transition: 'transform 0.15s ease', transform: `rotateZ(${showFields ? '180' : '0'}deg)` }}
              />
            </Col>
          )}
        </Row>
      </div>
      {(!collapsable || showFields) && (
        <>
          <div className="delimiter negative-mh-16" style={{ borderColor: headerState.delimiter }} />
          <Row center padding="l" className="pv-8">
            <Col width="calc(25% + 34px">
              <Text color="gray">{stickyHeaderColumn}</Text>
            </Col>
            <Col grow>
              <Text color="gray">
                {t('organizations.feed_exports.templates.header.input_data_source', { default: 'Original data feed' })}
              </Text>
            </Col>
            <Col width="40%">
              <Text color="gray">
                {t('organizations.feed_exports.templates.header.output_data_source', { default: 'Data feed export' })}
              </Text>
            </Col>
          </Row>
          <div className="delimiter negative-mh-16 delimiter-outline-default" />
        </>
      )}
    </div>
  );
};

Fieldset.propTypes = {
  collapsable: PropTypes.bool,
  colourless: PropTypes.bool,
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  stickyHeaderColumn: PropTypes.string,
  withoutGraph: PropTypes.bool,
  withoutGraphDescription: PropTypes.bool,
  inputGroups: PropTypes.array,
};

export default Fieldset;
